import ky from 'ky';

import environment from 'environment/environment';
import { ICall, ILiveGuestCommonPayload } from 'models/call.model';
import { IIceCandidate, IIceConnectionInfo, IIceServersResponse } from 'models/iceServer.model';
import { getHttpApiUrl } from 'utils/http.utils';

const BASE_URL = getHttpApiUrl(environment, 'liveguest-api');
const CALLS_URL = `${BASE_URL}/calls`;

const getHeaders = (token?: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export function fetchCallByIdentifier(callId: string): Promise<ICall> {
  return ky.get(`${CALLS_URL}/${callId}`).json();
}

export function authenticate(callId: string, password?: string): Promise<{ token: string }> {
  return ky
    .post(`${CALLS_URL}/${callId}/authenticate`, { json: { password: password || '' }, headers: getHeaders() })
    .json();
}

export function negotiate(callId: string, payload: ILiveGuestCommonPayload, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/negotiate`, { json: payload, headers: getHeaders(token) }).json();
}

export function negotiateVideoReturn(callId: string, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/negotiateVideoReturn`, { headers: getHeaders(token) }).json();
}

export function fetchIceServers(callId: string, token: string): Promise<IIceServersResponse> {
  return ky.get(`${CALLS_URL}/${callId}/iceServers`, { headers: getHeaders(token) }).json();
}

export function putIceCandidate(callId: string, candidate: IIceCandidate, token: string): Promise<void> {
  return ky.put(`${CALLS_URL}/${callId}/iceServers`, { json: candidate, headers: getHeaders(token) }).json();
}

export function sendIceConnection(callId: string, iceConnection: IIceConnectionInfo, token: string): Promise<void> {
  return ky
    .post(`${CALLS_URL}/${callId}/sendIceConnectionInfo`, { json: iceConnection, headers: getHeaders(token) })
    .json();
}

export function postVideoReturnReady(callId: string, payload: ILiveGuestCommonPayload, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/videoReturnReady`, { json: payload, headers: getHeaders(token) }).json();
}

export function prepare(callId: string, payload: ILiveGuestCommonPayload, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/prepare`, { json: payload, headers: getHeaders(token) }).json();
}

export function join(callId: string, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/join`, { headers: getHeaders(token) }).json();
}

export function leave(callId: string, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/leave`, { headers: getHeaders(token) }).json();
}

export function videoReturnReady(callId: string, payload: ILiveGuestCommonPayload, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/videoReturnReady`, { json: payload, headers: getHeaders(token) }).json();
}

export function intercomReady(callId: string, payload: ILiveGuestCommonPayload, token: string): Promise<void> {
  return ky.post(`${CALLS_URL}/${callId}/intercomReady`, { json: payload, headers: getHeaders(token) }).json();
}
