export const iceServers = [
  {
    urls: 'stun:stun.l.google.com:19302',
    username: '',
    credential: '',
  },
  {
    urls: 'stun:stun1.l.google.com:19302',
    username: '',
    credential: '',
  },
];
