import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';

import environment from 'environment/environment';
import { WebsocketClient } from 'http/WebSocketClient';
import { ILiveGuestWsMessage } from 'models/liveguestMessage.model';
import {
  hungUp,
  receivedJoinResponse,
  receivedLeaveResponse,
  receivedPrepareResponse,
  subscribeLiveServiceError,
  subscribeLiveServiceRequest,
  subscribeLiveServiceSuccess,
} from 'store/main/main.actions';
import { IntercomStatus, MainStatus, VideoReturnStatus } from 'store/main/main.types';
import {
  receivedJanusMediaEvent,
  receivedJanusTrickleEvent,
  receivedJanusWebRtcUpEvent,
} from 'store/mediaserver/mediaserver.actions';
import { mediaServer, stopMonitorMicrophone } from 'store/mediaserver/mediaserver.utils';
import { RootState } from 'store/rootReducer';
import store from 'store/store';
import { getWsApiUrl } from 'utils/http.utils';
import { logger } from 'utils/logger';

import { connectWebsocket, disconnectWebsocket } from './liveGuestWebsocket.actions';
import {
  onNegotiateVideoReturn,
  onStartIntercom,
  onStartVideoReturn,
  onStopIntercom,
  onStopVideoReturn,
  stopIntercomPeer,
  stopSenderPeer,
  stopVideoReturnPeer,
} from './liveGuestWebsocket.utils';

const BASE_URL = getWsApiUrl(environment, 'liveguest-api/live/calls');

const listenerMiddleware = createListenerMiddleware();

export const liveGuestWebsocketMiddleware = listenerMiddleware.middleware;

const LOGGER_PREFIX = '[LiveGuest WS]';

let socket: WebsocketClient<ILiveGuestWsMessage> | null = null;
let keepAliveInterval = -1;

const resetSocket = () => {
  if (socket) {
    logger.debug(LOGGER_PREFIX, 'Disconnecting WS...');
    socket.close();
    socket = null;
    clearInterval(keepAliveInterval);
  }
};

const startAppListening = listenerMiddleware.startListening as TypedStartListening<RootState, typeof store.dispatch>;

/**
 * statusIsAtLeast
 * @param status
 * @returns true if status passed in params is same or higher than 'state.main.status', else false
 */
const statusIsAtLeast = (mainStatus: MainStatus, status: MainStatus) => {
  return Object.keys(MainStatus).indexOf(mainStatus) >= Object.keys(MainStatus).indexOf(status);
};

startAppListening({
  actionCreator: connectWebsocket,
  effect(action, { getState, dispatch }) {
    resetSocket();

    dispatch(subscribeLiveServiceRequest());

    const callId = getState().call.call?.identifier;
    const token = getState().main.jwt;

    if (!callId || !token) {
      logger.error(LOGGER_PREFIX, 'Cannot stablish WS connection. callId or token are missing.');
      return;
    }

    logger.debug(LOGGER_PREFIX, 'Connecting to WS...');

    socket = new WebsocketClient<ILiveGuestWsMessage>(`${BASE_URL}/${callId}?token=${token}`);

    socket
      .connect(async (wsMessage) => {
        if (!(wsMessage instanceof Blob) && (wsMessage as any)?.cmd !== 'ping') {
          const { call: callState, main: mainState } = getState();

          if (wsMessage.data.response && statusIsAtLeast(mainState.status, MainStatus.TESTING)) {
            switch (wsMessage.data.response.type) {
              case 'prepare':
                dispatch(receivedPrepareResponse());
              // fallthrough to handle negotiation response !
              // eslint-disable-next-line no-fallthrough
              case 'negotiate': {
                try {
                  mediaServer.sendPeer?.processAnswer(wsMessage.data.response.sdp.content!);
                } catch (error) {
                  logger.error(LOGGER_PREFIX, 'negotiate', error);
                  stopMonitorMicrophone();
                  mediaServer.sendPeer!.stop();
                }
                break;
              }
              case 'negotiateVideoReturn':
                onNegotiateVideoReturn(callState.call!, wsMessage.data.response.sdp!);
                break;
              // case 'videoReturnReady':
              //   // ?
              //   break;
              case 'join':
                if (statusIsAtLeast(mainState.status, MainStatus.STARTING)) {
                  dispatch(receivedJoinResponse());
                }
                break;
              case 'leave':
                dispatch(receivedLeaveResponse());
                break;
              default:
                logger.warn(LOGGER_PREFIX, 'UNKNOWN MESSAGE', wsMessage);
            }
          }

          if (wsMessage.data.janusEvent && statusIsAtLeast(mainState.status, MainStatus.TESTING)) {
            const event = wsMessage.data.janusEvent;
            let usingTURN = false;
            if (event.connectionType === 'send') {
              usingTURN = (await mediaServer.sendPeer?.usingTURN) || false;
            }
            switch (event.type) {
              case 'trickle':
                dispatch(receivedJanusTrickleEvent(event.content));
                break;
              case 'webrtcup':
                dispatch(receivedJanusWebRtcUpEvent({ elementName: event.content, usingTURN }));
                break;
              case 'media':
                dispatch(receivedJanusMediaEvent({ elementName: event.content, usingTURN }));
                break;
              default:
            }
          }

          if (wsMessage.data.event) {
            const iceServers = callState.iceServers;
            const call = callState.call!;
            switch (wsMessage.data.event.type) {
              case 'startVideoReturn': {
                if (
                  (mainState.status === MainStatus.STARTING || mainState.status === MainStatus.LIVE) &&
                  mainState.videoReturnStatus === VideoReturnStatus.OFF
                ) {
                  onStartVideoReturn(call, wsMessage.data.event.sdp, iceServers);
                }
                break;
              }
              case 'stopVideoReturn':
                onStopVideoReturn();
                break;
              case 'negotiateVideoReturn':
                if (mainState.videoReturnStatus !== VideoReturnStatus.OFF) {
                  onNegotiateVideoReturn(call, wsMessage.data.event.sdp);
                }
                break;
              case 'startIntercom':
                if (
                  (mainState.status === MainStatus.STARTING || mainState.status === MainStatus.LIVE) &&
                  mainState.intercomStatus === IntercomStatus.OFF
                ) {
                  onStartIntercom(call, iceServers, wsMessage.data.event.sdp);
                }
                break;
              case 'stopIntercom':
                onStopIntercom();
                break;
              case 'hungUp': {
                const status = mainState.status;
                if (
                  status === MainStatus.TESTING ||
                  status === MainStatus.TEST_OK ||
                  status === MainStatus.TEST_FAILED ||
                  status === MainStatus.STARTING ||
                  status === MainStatus.LIVE
                ) {
                  stopSenderPeer();
                }
                stopVideoReturnPeer();
                stopIntercomPeer();
                dispatch(hungUp());
                break;
              }
              default:
                logger.warn(LOGGER_PREFIX, 'UNKNOWN MESSAGE', wsMessage);
            }
          }

          // if (response.hasIceCandidate()) {
          //         logger.log('[MediaserverMiddleware] Received an Ice Candidate on live service', response.getIceCandidate()!.toObject());
          //         const iceCandidate = response.getIceCandidate();
          //         let candidate = null;
          //         try {
          //           candidate = JSON.parse(iceCandidate!.getContent());
          //         } catch (error) {
          //           logger.error('[MediaserverMiddleware] Error parsing IceCandidate', iceCandidate);
          //         }
          //         if (candidate) {
          //           try {
          //             mediaServer.sendPeer?.addIceCandidate(candidate);
          //             if (!mediaServer.rcvVideoRetPeer) {
          //               videoRetIceCandidatesQueue.push(candidate);
          //             } else {
          //               mediaServer.rcvVideoRetPeer?.addIceCandidate(candidate);
          //             }
          //             if (!mediaServer.rcvIntercomPeer) {
          //               intercomIceCandidatesQueue.push(candidate);
          //             } else {
          //               mediaServer.rcvIntercomPeer?.addIceCandidate(candidate);
          //             }
          //           } catch (error) {
          //             logger.error('[MediaserverMiddleware] Add Ice Candidate error: ', error);
          //           }
          //         }
          //       }

          //       if (response.hasError()) {
          //         logger.log('[MediaserverMiddleware] Received an Error on live service', response.getError()!.toObject());
          //         const error = response.getError();
          //         if (error && error.getOrigin() === 'streamhub' && error.getType() === 'prepare') {
          //           dispatch(receivedStreamhubNotReady());
          //         } else if (error && error.getOrigin() === 'janus' && error.getType() === 'prepare' && error.getErrorCode() === 4110) {
          //           dispatch(receivedSessionAlreadyUsed());
          //         } else {
          //           dispatch(receivedError('error received')); // TODO response.getError()!));
          //         }
          //         clearTimeout(mediaServer.testTimeout);
          //       }

          //       if (response.hasEcho()) {
          //         //logger.log('Received an Echo on live service', response.getEcho()?.toObject());
          //       }
        } else {
          logger.warn(LOGGER_PREFIX, 'UNKNOWN MESSAGE', wsMessage);
        }
      })
      .then(() => {
        if (socket) {
          dispatch(subscribeLiveServiceSuccess());
        } else {
          dispatch(subscribeLiveServiceError());
        }
      })
      .catch(() => {
        dispatch(subscribeLiveServiceError());
      });

    keepAliveInterval = window.setInterval(() => {
      if (socket) {
        socket.send('keepAlive');
      } else {
        clearInterval(keepAliveInterval);
      }
      // it disconnects at about 60s without messages. Send ack message in intervals of 50s
    }, 50_000);
  },
});

startAppListening({
  actionCreator: disconnectWebsocket,
  effect(_action, { getState, dispatch }) {
    resetSocket();
  },
});
