import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { i18nInstance } from 'i18n/i18next';
import store from 'store/store';

import 'styles/index.scss';

const Call = React.lazy(() => import('components/Call/Call'));
const Check = React.lazy(() => import('components/Check/Check'));

export const App = () => {
  useEffect(() => {
    const themePreference = 'aw-theme-dark';
    localStorage.setItem('theme', themePreference);
    document.body.classList.add(themePreference);

    const clientId = localStorage.getItem('clientId');
    if (!clientId) {
      localStorage.setItem('clientId', uuidv4());
    }
  }, []);

  return (
    <I18nextProvider i18n={i18nInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/call/:identifier" element={<Call />} />
            <Route path="/check/:identifier" element={<Check />} />
            <Route path="/check" element={<Check />} />
            <Route path="*" element={<Navigate to="/check" replace />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};
