import AWRxStoreFactory from '@hai/aviwest-ui-kit/dist/js/rx/rx-store-factory';

export class SoundMeterFactory {
  context: AudioContext;
  script: ScriptProcessorNode;
  mic?: MediaStreamAudioSourceNode;
  instant: number;
  key: string;

  constructor(context: AudioContext, key: string) {
    this.context = context;
    this.script = this.context.createScriptProcessor(2048, 1, 1);
    this.instant = 0.0;
    this.key = key;
    let lastCall = 0;
    this.script.addEventListener('audioprocess', (event) => {
      const now = new Date().getTime();
      if (now - lastCall < 100) {
        return;
      }
      lastCall = now;

      const input = event.inputBuffer.getChannelData(0);
      let i;
      let sum = 0.0;
      for (i = 0; i < input.length; ++i) {
        sum += input[i] * input[i];
      }
      this.instant = Math.sqrt(sum / input.length);
      AWRxStoreFactory.getBasicStore('audioLevel').pushDataToSubject(key, this.instant);
    });
  }

  connectToSource(stream: MediaStream): void {
    if (!stream) {
      return;
    }
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination);
  }

  stop(): void {
    if (this.mic) {
      this.mic!.disconnect();
    }
    this.script.disconnect();
    AWRxStoreFactory.getBasicStore('audioLevel').pushDataToSubject(this.key, 0);
  }
}
