import { combineReducers } from '@reduxjs/toolkit';

import { callReducer } from './calls/calls.reducer';
import { loadingReducer } from './loading/loading.reducer';
import { mainReducer } from './main/main.reducer';

const rootReducer = combineReducers({
  main: mainReducer,
  call: callReducer,
  loading: loadingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
