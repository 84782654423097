import { Interop } from '@jitsi/sdp-interop';
import { EventEmitter } from 'events';
import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

import { IMediaDeviceInfo } from 'models/deviceInfo.model';
import { videoReturnIsFullyCompatible } from 'utils/global.utils';
import { logger } from 'utils/logger';

const MEDIA_CONSTRAINTS = {
  audio: true,
  video: {
    width: 640,
    framerate: 30,
  },
};

// Somehow, the UAParser constructor gets an empty window object.
// We need to pass the user agent string in order to get information
const ua = window.navigator.userAgent;
const parser = new UAParser(ua);
const browser = parser.getBrowser();

let usePlanB = false;
if (browser.name === 'Chrome' || browser.name === 'Chromium') {
  logger.debug(browser.name + ': using SDP PlanB');
  usePlanB = true;
}

/**
 * Returns a string representation of a SessionDescription object.
 */
const dumpSDP = (description) => {
  if (typeof description === 'undefined' || description === null) {
    return '';
  }
  return 'type: ' + description.type + '\r\n' + description.sdp;
};

/* Simulcast utilities */

const removeFIDFromOffer = (sdp) => {
  const n = sdp.indexOf('a=ssrc-group:FID');

  if (n > 0) {
    return sdp.slice(0, n);
  } else {
    return sdp;
  }
};

const getSimulcastInfo = (videoStream: MediaStream) => {
  const videoTracks = videoStream.getVideoTracks();
  if (!videoTracks.length) {
    logger.warn('No video tracks available in the video stream');
    return '';
  }
  const lines = [
    'a=x-google-flag:conference',
    'a=ssrc-group:SIM 1 2 3',
    'a=ssrc:1 cname:localVideo',
    'a=ssrc:1 msid:' + videoStream.id + ' ' + videoTracks[0].id,
    'a=ssrc:1 mslabel:' + videoStream.id,
    'a=ssrc:1 label:' + videoTracks[0].id,
    'a=ssrc:2 cname:localVideo',
    'a=ssrc:2 msid:' + videoStream.id + ' ' + videoTracks[0].id,
    'a=ssrc:2 mslabel:' + videoStream.id,
    'a=ssrc:2 label:' + videoTracks[0].id,
    'a=ssrc:3 cname:localVideo',
    'a=ssrc:3 msid:' + videoStream.id + ' ' + videoTracks[0].id,
    'a=ssrc:3 mslabel:' + videoStream.id,
    'a=ssrc:3 label:' + videoTracks[0].id,
  ];

  lines.push('');

  return lines.join('\n');
};

/**
 * Wrapper object of an RTCPeerConnection. This object is aimed to simplify the
 * development of WebRTC-based applications.
 **
 * @param {String} mode Mode in which the PeerConnection will be configured.
 *  Valid values are: 'recvonly', 'sendonly', and 'sendrecv'
 * @param localVideo Video tag for the local stream
 * @param remoteVideo Video tag for the remote stream
 * @param {MediaStream} videoStream Stream to be used as primary source
 *  (typically video and audio, or only video if combined with audioStream) for
 *  localVideo and to be added as stream to the RTCPeerConnection
 * @param {MediaStream} audioStream Stream to be used as second source
 *  (typically for audio) for localVideo and to be added as stream to the
 *  RTCPeerConnection
 */
export class WebRtcPeer extends EventEmitter {
  private readonly _mode: string;
  private _id: string;
  private _guid = uuidv4();
  private _localVideos: { [id: string]: HTMLVideoElement };
  private _remoteVideos: { [id: string]: HTMLVideoElement | HTMLAudioElement };
  private _peerConnection: RTCPeerConnection;
  private _configuration: RTCConfiguration;
  private _localStream: MediaStream | null;
  private _multistream: boolean;
  private _simulcast: boolean;
  private _candidategatheringdone: boolean;
  private _interop = new Interop();
  private _candidatesQueue: RTCIceCandidate[] = [];
  private _candidatesQueueOut: RTCIceCandidate[] = [];
  private _devices: Record<string, IMediaDeviceInfo>;
  private _deviceChangeTimeout: any;
  private _statsInterval: any;
  private _iceNegotiationOffer: string | null;
  private _mediaNegotiationOffer: string | null;
  private _firstConnection: boolean;
  private _loggingId: string;
  private _usingTURN: boolean;

  public get id() {
    return this._id;
  }

  public get peerConnection() {
    return this._peerConnection;
  }

  public get devices() {
    return this._devices;
  }

  public get localVideos() {
    return this._localVideos;
  }

  public get remoteVideos() {
    return this._remoteVideos;
  }

  public get usingTURN() {
    return this._usingTURN;
  }

  public getIceNegotiationOffer() {
    return this._iceNegotiationOffer;
  }

  public getMediaNegotiationOffer() {
    return this._mediaNegotiationOffer;
  }

  constructor(mode: 'recv' | 'send', options?: any) {
    super();

    options = options || {};
    this._mode = mode;
    this._id = options.id || this._guid;
    this._localVideos = {};
    this._remoteVideos = {};
    this._localStream = null;
    this._peerConnection = options.peerConnection;
    this._configuration = options.configuration;
    this._simulcast = options.simulcast || false;
    this._multistream = options.multistream || false;
    this._loggingId = options.loggingId || mode;
    this._candidategatheringdone = false;
    this._iceNegotiationOffer = null;
    this._mediaNegotiationOffer = null;
    this._firstConnection = true;
    this._usingTURN = false;
    this._deviceChangeTimeout = null;

    this._devices = {};
    this._statsInterval = null;

    if (options.onicecandidate) {
      this.on('icecandidate', options.onicecandidate);
    }

    if (options.ondevicechange) {
      this.on('devicechange', options.ondevicechange);
    }

    if (options.onnegotiationneeded) {
      this.on('negotiationneeded', options.onnegotiationneeded);
    }

    if (options.onpermissionchange) {
      this.on('permissionchange', options.onpermissionchange);
    }

    if (options.oncandidategatheringdone) {
      this.on('candidategatheringdone', options.oncandidategatheringdone);
    }

    if (options.oniceconnectionstatechange) {
      this.on('iceconnectionstatechange', options.oniceconnectionstatechange);
    }
  }

  private initPeerConnection(configuration) {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Init PeerConnection`);
    this._peerConnection = new RTCPeerConnection(configuration);

    // Handling listeners
    this._peerConnection.addEventListener('icecandidate', this.iceCandidateHandler.bind(this));
    this._peerConnection.addEventListener('track', (evt) => {
      logger.debug(`[WebRtcPeer][${this._loggingId}] New track event:`, evt.streams[0]);
    });
    this._peerConnection.addEventListener('negotiationneeded', () => {
      logger.debug(`[WebRtcPeer][${this._loggingId}] Negotiation needed`);
    });

    // Handling IceCandidate
    // When PeerConnection state become 'stable'
    this._peerConnection.addEventListener('signalingstatechange', () => {
      logger.info(`[WebRtcPeer][${this._loggingId}] Signaling State: ${this._peerConnection.signalingState}`);
      if (this._peerConnection.signalingState === 'stable') {
        while (this._candidatesQueue.length) {
          const candidate = this._candidatesQueue.shift();
          this._peerConnection.addIceCandidate(candidate!);
        }
      }
    });

    this._peerConnection.addEventListener('iceconnectionstatechange', async (evt) => {
      logger.info(`[WebRtcPeer][${this._loggingId}] Ice Connection State: ${this._peerConnection.iceConnectionState}`);
      if (
        this._peerConnection.iceConnectionState === 'failed' ||
        this._peerConnection.iceConnectionState === 'disconnected'
      ) {
        /* Request ICE restart */
        this._usingTURN = false;
        this.restartIce();
      } else if (this._peerConnection.iceConnectionState === 'connected') {
        this._firstConnection = false;
        this._iceNegotiationOffer = null;
        await this.checkTURNServer();
      }
      this.emit('iceconnectionstatechange', this._peerConnection.iceConnectionState, this._usingTURN);
    });

    this._peerConnection.addEventListener('connectionstatechange', (evt) => {
      logger.info(`[WebRtcPeer][${this._loggingId}] Connection State: ${this._peerConnection.connectionState}`);
    });

    // When new listener comes in, and we already buffered IceCandidates
    this.on('newListener', (event, listener) => {
      if (event === 'icecandidate' || event === 'candidategatheringdone') {
        while (this._candidatesQueueOut.length) {
          const candidate = this._candidatesQueueOut.shift();
          if (!candidate === (event === 'candidategatheringdone')) {
            listener(candidate);
          }
        }
      }
    });
  }

  public async restartIce() {
    if (this._candidategatheringdone && !this._firstConnection) {
      logger.warn(`[WebRtcPeer][${this._loggingId}] Ice restart required`);
      this._candidategatheringdone = false;
      try {
        if (this._mode === 'send') {
          const offer = await this._peerConnection.createOffer({ iceRestart: true });
          await this._peerConnection.setLocalDescription(offer);
          this._iceNegotiationOffer = offer.sdp!;
        }
        this.emit('negotiationneeded', 'ICE');
      } catch (err) {
        logger.error(`[WebRtcPeer][${this._loggingId}] Ice restart error: ${err}`);
      }
    }
  }

  private iceCandidateHandler(event) {
    const candidate = event.candidate;
    if (this.listenerCount('icecandidate') || this.listenerCount('candidategatheringdone')) {
      if (candidate) {
        this.emit('icecandidate', candidate);
        this._candidategatheringdone = false;
      } else if (!this._candidategatheringdone) {
        this.emit('candidategatheringdone');
        this._candidategatheringdone = true;
      }
    } else if (!this._candidategatheringdone) {
      if (candidate) {
        this._candidatesQueueOut.push(candidate);
      } else {
        this._candidategatheringdone = true;
      }
    } else {
      logger.error(`[WebRtcPeer][${this._loggingId}] New ice candidate, but gathering done, is that possible ?`);
    }
  }

  /**
   * Initiate
   */
  public async start(mediaConstraint?: MediaStreamConstraints): Promise<void> {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Start Preview with constraints: `, mediaConstraint);
    if (this._peerConnection && this._peerConnection.signalingState !== 'closed') {
      this.clearPeerConnection();
    }
    this.initPeerConnection(this._configuration);
    mediaConstraint = mediaConstraint || MEDIA_CONSTRAINTS;
    if (this._mode === 'send') {
      logger.debug(`[WebRtcPeer][${this._loggingId}] Request User Media with constraints:`, mediaConstraint);
      try {
        this.tearDownLocalElements();
        this.clearLocalStream();
        if (!navigator.mediaDevices) {
          throw new Error('Please make sure that your browser have the permission to start your Camera and Microphone');
        }
        this._localStream = await navigator.mediaDevices.getUserMedia(mediaConstraint);
        logger.debug(`[WebRtcPeer][${this._loggingId}] Requested User Media:`, this._localStream);
        // Does not work for Android/Chrome...
        this.emit('permissionchange', true);
        navigator.mediaDevices.addEventListener('devicechange', async () => {
          // Because that event can be triggered multiple times in a few milliseconds (thanks Chrome !), we add some debounce
          if (this._deviceChangeTimeout) {
            clearTimeout(this._deviceChangeTimeout);
          }
          this._deviceChangeTimeout = setTimeout(() => {
            this.computeDevices();
          }, 500);
        });
        // ... but we call it manually anyway
        await this.computeDevices();
      } catch (error) {
        logger.error(`[WebRtcPeer][${this._loggingId}] Error requesting browser permissions: `, error);
        this.emit('permissionchange', false, error.message);
      }
    }

    if (this._localStream) {
      logger.debug(`[WebRtcPeer][${this._loggingId}] New PeerConnection -> We add tracks to the PeerConnection`);
      this._localStream.getTracks().forEach((track) => {
        logger.debug(`[WebRtcPeer][${this._loggingId}] Adding new media track:`, track);
        if (track.kind === 'audio') {
          track.addEventListener('ended', () => this.forceAudioReset());
        }
        this._peerConnection.addTrack(track);
      });
    }

    if (this._localStream && Object.keys(this._localVideos).length) {
      this.setUpLocalElements();
    }
  }

  private async forceAudioReset() {
    logger.warn(`[WebRtcPeer][${this._loggingId}] Forcing audio reset !`);
    if (this._localStream) {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        if (audioStream) {
          const updatedStream = new MediaStream();
          logger.warn(`[WebRtcPeer][${this._loggingId}] Adding current Video Tracks to the new stream`);
          this._localStream.getVideoTracks().forEach((currentVideoTrack) => updatedStream.addTrack(currentVideoTrack));
          logger.warn(`[WebRtcPeer][${this._loggingId}] Adding new Audio Tracks to the new stream`);
          let needRenegotiation = false;
          audioStream.getAudioTracks().forEach((audioTrack) => {
            audioTrack.addEventListener('ended', () => this.forceAudioReset());
            updatedStream.addTrack(audioTrack);
            if (this._peerConnection.connectionState === 'connected') {
              const sender = this._peerConnection
                .getSenders()
                .find((currentSender) => currentSender.track && currentSender.track.kind === 'audio');
              if (sender) {
                try {
                  logger.warn(`[WebRtcPeer][${this._loggingId}] Replacing sender track:`);
                  logger.warn(`[WebRtcPeer][${this._loggingId}] OLD:`, sender.track);
                  logger.warn(`[WebRtcPeer][${this._loggingId}] NEW:`, audioTrack);
                  sender.replaceTrack(audioTrack);
                } catch (e) {
                  logger.warn(`[WebRtcPeer][${this._loggingId}] Error occurred while replacing audio track`, e);
                  // If there is an error, we need to renegotiate
                  needRenegotiation = true;
                }
              }
            }
          });
          this._localStream = updatedStream;
          this.setUpLocalElements();
          if (needRenegotiation) {
            logger.warn(`[WebRtcPeer][${this._loggingId}] Replacing AudioTrack needs renegotiation`);
            const offer = await this._peerConnection.createOffer();
            await this._peerConnection.setLocalDescription(offer);
            this._mediaNegotiationOffer = offer.sdp!;
            this.emit('negotiationneeded', 'MEDIA');
          }
        }
      } catch (e) {
        logger.error(`[WebRtcPeer][${this._loggingId}] Audio reset error: `, e);
      }
    }
  }

  public attachLocalElement = (element: HTMLVideoElement) => {
    if (!element) {
      logger.warn(`[WebRtcPeer][${this._loggingId}] Cannot attach null element`);
      return;
    }
    this._localVideos[element.id] = element;
    this.setUpLocalElement(element);
  };

  public attachRemoteElement = (element: HTMLVideoElement | HTMLAudioElement) => {
    if (!element) {
      logger.warn(`[WebRtcPeer][${this._loggingId}] Cannot attach null element`);
      return;
    }
    this._remoteVideos[element.id] = element;
    const remoteStream = this.getRemoteStream();
    if (remoteStream) {
      this.setUpRemoteElement(element);
    }
  };

  /**
   * Log Tracks of current PeerConnection
   * @private
   */
  private logTracks() {
    if (this._mode === 'send') {
      logger.debug(
        `[WebRtcPeer][${this._loggingId}] Senders Tracks:`,
        this._peerConnection.getSenders().map((sender) => sender.track)
      );
    } else {
      logger.debug(
        `[WebRtcPeer][${this._loggingId}] Receivers Tracks:`,
        this._peerConnection.getReceivers().map((sender) => sender.track)
      );
    }
  }

  /**
   * Using this method the user can get the local stream.
   */
  public getLocalStream(): MediaStream | null {
    if (this._localStream) {
      return this._localStream;
    }
    return null;
  }

  /**
   * Using this method the user can get the remote stream.
   */
  public getRemoteStream(): MediaStream | null {
    if (this._peerConnection) {
      const stream = new MediaStream();
      this._peerConnection.getReceivers().forEach((sender) => {
        stream.addTrack(sender.track);
      });
      return stream;
    }
    return null;
  }

  /**
   * Using this method the user can get peerconnection’s local session descriptor.
   */
  public getLocalSessionDescriptor(): RTCSessionDescription | null {
    return this._peerConnection.localDescription;
  }

  /**
   * Using this method the user can get peerconnection’s remote session descriptor.
   */
  public getRemoteSessionDescriptor(): RTCSessionDescription | null {
    return this._peerConnection.remoteDescription;
  }

  public async processAnswer(sdpAnswer: string) {
    let answer = new RTCSessionDescription({
      type: 'answer',
      sdp: sdpAnswer,
    });

    if (this._multistream && usePlanB) {
      const planBAnswer = this._interop.toPlanB(answer);
      logger.debug(`[WebRtcPeer][${this._loggingId}] asnwer::planB`, dumpSDP(planBAnswer));
      answer = planBAnswer;
    }

    logger.debug(`[WebRtcPeer][${this._loggingId}] SDP answer received, setting remote description`);

    if (this._peerConnection.signalingState === 'closed') {
      throw new Error('PeerConnection is closed');
    }

    await this._peerConnection.setRemoteDescription(answer);
    if (this._mode !== 'send') {
      this.setUpRemoteElements();
    }
  }

  /**
   * Callback function invoked when a SDP offer is received. Developers are expected to invoke this function in order to complete the SDP negotiation. This method has two parameters:
   *
   * @param sdpOffer Description of sdpOffer
   */
  public async processOffer(sdpOffer: string) {
    let offer = new RTCSessionDescription({
      type: 'offer',
      sdp: sdpOffer,
    });

    if (this._multistream && usePlanB) {
      const planBOffer = this._interop.toPlanB(offer);
      logger.debug(`[WebRtcPeer][${this._loggingId}] offer::planB`, dumpSDP(planBOffer));
      offer = planBOffer;
    }

    logger.debug(`[WebRtcPeer][${this._loggingId}] SDP offer received, setting remote description`);

    if (this._peerConnection.signalingState === 'closed') {
      throw new Error('PeerConnection is closed');
    }

    await this._peerConnection.setRemoteDescription(offer);
    this.setUpRemoteElements();
    let answer = await this._peerConnection.createAnswer({ offerToReceiveVideo: videoReturnIsFullyCompatible() });
    answer = this.mangleSdpToAddSimulcast(answer);
    logger.debug(`[WebRtcPeer][${this._loggingId}] Created SDP answer`);
    await this._peerConnection.setLocalDescription(answer);
    let localDescription = this._peerConnection.localDescription;
    if (this._multistream && usePlanB) {
      localDescription = this._interop.toUnifiedPlan(localDescription);
      logger.debug(`[WebRtcPeer][${this._loggingId}] answer::origPlanB->UnifiedPlan`, dumpSDP(localDescription));
    }
    logger.debug(`[WebRtcPeer][${this._loggingId}] Local description set\n`, localDescription!.sdp);
    return localDescription!.sdp;
  }

  /**
   * This method frees the resources used by WebRtcPeer.
   */
  public stop() {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Stopping`);
    this.clearPeerConnection();
    this.clearLocalStream();

    if (Object.keys(this._localVideos).length) {
      this.tearDownLocalElements();
      this._localVideos = {};
    }
    if (Object.keys(this._remoteVideos).length) {
      this.tearDownRemoteElements();
      this._remoteVideos = {};
    }

    if (typeof window !== 'undefined' && (window as any).cancelChooseDesktopMedia !== undefined) {
      (window as any).cancelChooseDesktopMedia(this._guid);
    }
  }

  /**
   * Callback function invoked when an ICE candidate is received. Developers are expected to invoke this function in order to complete the SDP negotiation. This method has two parameters:
   *
   * @param iceCandidate Literal object with the ICE candidate description
   */
  public async addIceCandidate(iceCandidate: RTCIceCandidate) {
    const candidate = new RTCIceCandidate(iceCandidate);
    logger.debug(`[WebRtcPeer][${this._loggingId}] Remote ICE candidate received`, iceCandidate);
    switch (this._peerConnection.signalingState) {
      case 'closed':
        throw new Error('PeerConnection object is closed');
      case 'stable':
        if (this._peerConnection.remoteDescription) {
          await this._peerConnection.addIceCandidate(candidate);
        }
        break;
      default:
        this._candidatesQueue.push(candidate);
    }
  }

  /**
   * Creates an offer that is a request to find a remote peer with a specific configuration.
   */
  public async generateOffer(): Promise<string> {
    if (this._mode === 'send') {
      this._peerConnection.getTransceivers().forEach((transceiver) => {
        transceiver.direction = 'sendonly';
      });
    }

    let offer = await this._peerConnection.createOffer();
    logger.debug(`[WebRtcPeer][${this._loggingId}] Created SDP offer`);
    offer = this.mangleSdpToAddSimulcast(offer);
    await this._peerConnection.setLocalDescription(offer);
    let localDescription = this._peerConnection.localDescription;
    logger.debug(`[WebRtcPeer][${this._loggingId}] Local description set\n`, localDescription!.sdp);
    if (this._multistream && usePlanB) {
      localDescription = this._interop.toUnifiedPlan(localDescription);
      logger.debug(`[WebRtcPeer][${this._loggingId}] offer::origPlanB->UnifiedPlan`, dumpSDP(localDescription));
    }
    return localDescription!.sdp;
  }

  public muteLocalVideo(mute: boolean) {
    if (this._localStream) {
      logger.log('TRACKS: ', this._localStream.getTracks());
      logger.log('VIDEO TRACKS: ', this._localStream.getVideoTracks());
      this._localStream.getVideoTracks().forEach((track) => (track.enabled = !mute));
    }
  }

  public muteLocalAudio(mute: boolean) {
    if (this._localStream) {
      logger.log('TRACKS: ', this._localStream.getTracks());
      logger.log('AUDIO TRACKS: ', this._localStream.getAudioTracks());
      this._localStream.getAudioTracks().forEach((track) => (track.enabled = !mute));
    }
  }

  public muteRemoteVideo(mute: boolean) {
    const stream = this.getRemoteStream();
    if (stream) {
      stream.getVideoTracks().forEach((track) => (track.enabled = !mute));
    }
  }

  public muteRemoteAudio(mute: boolean) {
    const stream = this.getRemoteStream();
    if (stream) {
      stream.getAudioTracks().forEach((track) => (track.enabled = !mute));
    }
  }

  private mangleSdpToAddSimulcast(answer) {
    if (this._simulcast) {
      if (browser.name === 'Chrome' || browser.name === 'Chromium') {
        logger.debug(`[WebRtcPeer][${this._loggingId}] Adding multicast info`);
        answer = new RTCSessionDescription({
          type: answer.type,
          sdp: removeFIDFromOffer(answer.sdp) + getSimulcastInfo(this._localStream!),
        });
      } else {
        logger.debug(`[WebRtcPeer][${this._loggingId}] Simulcast is only available in Chrome browser.`);
      }
    }
    return answer;
  }

  private async computeDevices() {
    const devices: Record<string, IMediaDeviceInfo> = {};
    if (navigator.mediaDevices.enumerateDevices) {
      const devicesEnumerated = await navigator.mediaDevices.enumerateDevices();
      devicesEnumerated.forEach((device) => {
        if (device.deviceId !== '') {
          if (device.kind === 'audioinput' || device.kind === 'videoinput') {
            devices[device.deviceId] = device.toJSON() as IMediaDeviceInfo;
          }
        }
      });
    }
    this._devices = devices;
    this.emit('devicechange', Object.values(this._devices));
  }

  private setUpLocalElement = (element: HTMLVideoElement) => {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Assigning stream to element:`, element);
    element.srcObject = this._localStream;
    element.muted = true;
  };

  private tearDownLocalElement = (element: HTMLVideoElement) => {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Cleaning element:`, element);
    element.pause();
    element.srcObject = null;
    element.load();
    element.muted = false;
  };

  private setUpLocalElements = () => {
    Object.values(this._localVideos).forEach(this.setUpLocalElement);
  };

  private tearDownLocalElements = () => {
    Object.values(this._localVideos).forEach(this.tearDownLocalElement);
  };

  private setUpRemoteElement = (element: HTMLVideoElement | HTMLAudioElement) => {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Assigning stream to element:`, element);
    const stream = this.getRemoteStream();
    if (stream) {
      element.pause();
      element.srcObject = stream;
      logger.debug(`[WebRtcPeer][${this._loggingId}] Remote stream:`, stream);
      element.load();
      if (browser.name === 'Firefox' || browser.name === 'Safari') {
        logger.debug(`[WebRtcPeer][${this._loggingId}] Playing stream:`, stream);
        element.play();
      }
    }
  };

  private tearDownRemoteElement = (element: HTMLVideoElement | HTMLAudioElement) => {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Cleaning element:`, element);
    element.pause();
    element.srcObject = null;
    element.load();
    element.muted = false;
  };

  private setUpRemoteElements = () => {
    Object.values(this._remoteVideos).forEach(this.setUpRemoteElement);
  };

  private tearDownRemoteElements = () => {
    Object.values(this._remoteVideos).forEach(this.tearDownRemoteElement);
  };

  private clearLocalStream() {
    if (this._localStream) {
      this._localStream.getTracks().forEach((track) => {
        logger.debug(`[WebRtcPeer][${this._loggingId}] Stopping track from localStream:`, track);
        track.stop();
      });
      this._localStream = null;
    }
  }

  private clearPeerConnection() {
    logger.debug(`[WebRtcPeer][${this._loggingId}] Clearing peer connection`);
    this.stopLoggingStats();
    try {
      if (this._peerConnection) {
        if (this._peerConnection.signalingState === 'closed') {
          return;
        }
        this._peerConnection.getSenders().forEach((sender) => {
          this._peerConnection.removeTrack(sender);
        });

        this._peerConnection.close();
      }
      this._iceNegotiationOffer = null;
      this._mediaNegotiationOffer = null;
      this._candidategatheringdone = false;
      this._candidatesQueue = [];
      this._candidatesQueueOut = [];
      this._firstConnection = true;
    } catch (err) {
      logger.error(`[WebRtcPeer][${this._loggingId}] Error clearing PeerConnection:`, this._peerConnection);
    }
  }

  public async logStats(types?: string[]) {
    try {
      const stats = await this._peerConnection.getStats();
      logger.debug(`[WebRtcPeer][${this._loggingId}] ------------ Stats: -------------`);
      stats.forEach((stat) => {
        if (!types || types.indexOf(stat.type) >= 0) {
          logger.debug(`[WebRtcPeer][${this._loggingId}] ${stat.type}:`, stat);
        }
      });
      logger.debug(`[WebRtcPeer][${this._loggingId}] ----------------------------------`);
    } catch (err) {
      logger.error(`[WebRtcPeer][${this._loggingId}] Error retrieving stats:`, err);
    }
  }

  public startLoggingStats(interval: number = 1000, types?: string[]) {
    if (this._statsInterval) {
      this.stopLoggingStats();
    }
    this._statsInterval = setInterval(async () => {
      await this.logStats(types);
    }, interval);
  }

  public stopLoggingStats() {
    clearInterval(this._statsInterval);
    this._statsInterval = null;
  }

  /**
   * Restrict current RTCPeerConnection to a certain bitrate
   *
   * Source: https://github.com/webrtc/samples/blob/gh-pages/src/content/peerconnection/bandwidth/js/main.js
   *
   * @param bandwidth In kbps/s. For unlimited bitrate, use -1
   */
  public async restrictBandwidth(bandwidth: number) {
    if (this._mode !== 'send') {
      logger.error(`[WebRtcPeer][${this._loggingId}] PeerConnection is not of sender type !`);
      return;
    }
    if (this._peerConnection.signalingState !== 'stable') {
      logger.warn(`[WebRtcPeer][${this._loggingId}] Cannot restrict bandwidth to non stable connection`);
      return;
    }

    // In Chrome, FF and Safari, use RTCRtpSender.setParameters to change bandwidth without
    // (local) renegotiation. Note that this will be within the envelope of
    // the initial maximum bandwidth negotiated via SDP.
    if (
      (browser.name?.toLowerCase().indexOf('chrome') !== -1 ||
        browser.name?.toLowerCase().indexOf('safari') !== -1 ||
        (browser.name?.toLowerCase().indexOf('firefox') !== -1 &&
          browser.version &&
          parseInt(browser.version!) >= 64)) &&
      'setParameters' in RTCRtpSender.prototype
    ) {
      try {
        //We only need to constraint video (audio bitrate will always remain acceptable)
        const sender = this._peerConnection.getSenders().find((currentSender) => currentSender.track?.kind === 'video');
        if (!sender) {
          logger.warn(`[WebRtcPeer][${this._loggingId}] No sender related to video media track found !`);
          return;
        }
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        if (bandwidth < 0) {
          delete parameters.encodings[0].maxBitrate;
        } else {
          parameters.encodings[0].maxBitrate = bandwidth * 1000;
        }
        await sender.setParameters(parameters);
        logger.warn(
          `[WebRtcPeer][${this._loggingId}] Applying encoder bitrate restrictions: max bitrate set to ${bandwidth}kbps`
        );
      } catch (e) {
        logger.error(`[WebRtcPeer][${this._loggingId}] Could not limit encoding bitrate: `, e);
      }
      return;
    }
    // Fallback to the SDP munging with local renegotiation way of limiting
    // the bandwidth.
    try {
      const offer = await this._peerConnection.createOffer();
      await this._peerConnection.setLocalDescription(offer);
      const remoteDescription = this._peerConnection.remoteDescription!;
      const desc = {
        type: remoteDescription.type,
        sdp:
          bandwidth < 0
            ? WebRtcPeer.removeBandwidthRestriction(remoteDescription.sdp)
            : WebRtcPeer.updateBandwidthRestriction(remoteDescription.sdp, bandwidth),
      };
      logger.warn(
        `[WebRtcPeer][${this._loggingId}] Applying bandwidth restriction to setRemoteDescription:\n ${desc.sdp}`
      );
      await this._peerConnection.setRemoteDescription(desc);
    } catch (e) {
      logger.error(`[WebRtcPeer][${this._loggingId}] Error munging with SDP to limit bandwidth:`, e);
    }
  }

  private static updateBandwidthRestriction(sdp: string, bandwidth: number): string {
    let modifier = 'AS';
    if (browser.name?.indexOf('firefox') !== -1) {
      bandwidth = (bandwidth >>> 0) * 1000;
      modifier = 'TIAS';
    }
    if (sdp.indexOf('b=' + modifier + ':') === -1) {
      // insert b= after c= line.
      sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
    } else {
      sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
    }
    return sdp;
  }

  private static removeBandwidthRestriction(sdp: string): string {
    return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
  }

  private async checkTURNServer(): Promise<boolean> {
    if (this._peerConnection.signalingState === 'closed') {
      return false;
    }
    try {
      const stats = await this._peerConnection.getStats();
      let selectedLocalCandidate: string | undefined;
      let selectedRemoteCandidate: string | undefined;
      stats.forEach((stat) => {
        if (stat.type === 'candidate-pair' && stat.state === 'succeeded') {
          selectedLocalCandidate = stat.localCandidateId;
          selectedRemoteCandidate = stat.remoteCandidateId;
          logger.debug(
            `[WebRtcPeer][${this._loggingId}] Selected local candidate:`,
            stats.get(selectedLocalCandidate!)
          );
          logger.debug(
            `[WebRtcPeer][${this._loggingId}] Selected remote candidate:`,
            stats.get(selectedRemoteCandidate!)
          );
        }
      });
      const localCandidateUsingRelay =
        selectedLocalCandidate != null && stats.get(selectedLocalCandidate)?.candidateType === 'relay';
      const remoteCandidateUsingRelay =
        selectedRemoteCandidate != null && stats.get(selectedRemoteCandidate)?.candidateType === 'relay';
      this._usingTURN = localCandidateUsingRelay || remoteCandidateUsingRelay;
      logger.warn(`[WebRtcPeer][${this._loggingId}] Using TURN ? ${this._usingTURN ? 'YES' : 'NO'}`);
      return this._usingTURN;
    } catch (e) {
      logger.error(`[WebRtcPeer][${this._loggingId}] Error detecting selected local candidate:`, e);
      return false;
    }
  }

  public async applyVideoConstraints(constraints: MediaTrackConstraints) {
    if (!this._localStream) {
      logger.warn('No way to apply constraints on current local stream');
      return;
    }
    const promises: Promise<void>[] = [];
    this.getLocalStream()
      ?.getVideoTracks()
      ?.forEach((currentTrack) => {
        promises.push(currentTrack.applyConstraints(constraints));
      });
    return Promise.all(promises);
  }

  /**
   * Create a WebRtcPeer as receive only.
   */
  public static WebRtcPeerRecvonly(options: object): WebRtcPeer {
    return new WebRtcPeer('recv', options);
  }

  /**
   * Create a WebRtcPeer as send only.
   */
  public static WebRtcPeerSendonly(options: object): WebRtcPeer {
    return new WebRtcPeer('send', options);
  }
}
