import { createReducer } from '@reduxjs/toolkit';

import { ICall } from 'models/call.model';
import { IIceServer } from 'models/iceServer.model';

import { getCall, getIceServers } from './calls.actions';

export interface ICallState {
  call?: ICall;
  iceServers: IIceServer[];
}

const INITIAL_STATE: ICallState = {
  call: undefined,
  iceServers: [],
};

export const callReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(getCall.fulfilled, (state, action) => {
    state.call = action.payload;
  });

  builder.addCase(getIceServers.fulfilled, (state, action) => {
    state.iceServers = action.payload;
  });
});
