import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'liveGuestWebsocket';

export const ACTIONS = {
  CONNECT_WEBSOCKET: `${PREFIX}/connectWebsocket`,
  DISCONNECT_WEBSOCKET: `${PREFIX}/disconnectWebsocket`,
};

export const connectWebsocket = createAction<void>(ACTIONS.CONNECT_WEBSOCKET);
export const disconnectWebsocket = createAction(ACTIONS.DISCONNECT_WEBSOCKET);
