import { LiveGuestConnectionType } from '@hai/orion-constants';

import { ICall, ILiveGuestCommonPayload } from 'models/call.model';
import { IIceCandidate, IIceServer } from 'models/iceServer.model';
import { ISdp } from 'models/sdp.model';
import {
  addIceCandidate,
  intercomReady,
  intercomRequested,
  videoReturnReady,
  videoReturnRequested,
} from 'store/calls/calls.actions';
import { muteVideoReturnAudio, videoReturnStopped } from 'store/main/main.actions';
import { IntercomStatus, SignalingStatus, VideoReturnStatus } from 'store/main/main.types';
import { iceRestart, intercomStopped } from 'store/mediaserver/mediaserver.actions';
import {
  intercomIceCandidatesQueue,
  mediaServer,
  stopMonitorIntercomAudio,
  stopMonitorMicrophone,
  stopMonitorVideoReturnAudio,
  videoRetIceCandidatesQueue,
} from 'store/mediaserver/mediaserver.utils';
import store from 'store/store';
import { extractH264ProfileLevelId, videoReturnIsAudioOnly } from 'utils/global.utils';
import { logger } from 'utils/logger';
import { WebRtcPeer } from 'utils/WebRtcPeer';

export async function onStartVideoReturn(call: ICall, sdp: ISdp, iceServers: IIceServer[]) {
  store.dispatch(videoReturnRequested());

  const identifier = call.identifier;

  mediaServer.rcvVideoRetPeer = WebRtcPeer.WebRtcPeerRecvonly({
    loggingId: 'vidRet',
    onicecandidate: (candidate: RTCIceCandidate) => {
      const payload: IIceCandidate = {
        identifier: identifier,
        origin: 'client',
        content: JSON.stringify(candidate.toJSON()),
        connectionType: 'receive',
      };
      store.dispatch(addIceCandidate({ callId: identifier, candidate: payload }));
    },
    onnegotiationneeded: () => {
      // If we already are connected, we dispatch 'iceRestart' immediately
      if (store.getState().main.signalingStatus === SignalingStatus.connected) {
        store.dispatch(iceRestart({ call, mode: LiveGuestConnectionType.receive }));
      }
    },
    oniceconnectionstatechange: (status: string, usingTURN: boolean) => {
      logger.debug(
        `[MediaserverMiddleware][RecvVidRet] ICE connection state changed: ${status} - using TURN: ${usingTURN}`
      );
    },
    configuration: { iceServers },
  });

  try {
    await mediaServer.rcvVideoRetPeer.start();
    if (videoRetIceCandidatesQueue.length > 0) {
      while (videoRetIceCandidatesQueue.length) {
        const candidate = videoRetIceCandidatesQueue.shift()!;
        mediaServer.rcvVideoRetPeer.addIceCandidate(candidate);
      }
    }

    const offer = sdp.content!;
    logger.log('[MediaserverMiddleware][RecvVidRet] Process Sdp offer', offer);

    if (store.getState().main.videoReturnStatus !== VideoReturnStatus.OFF) {
      const sdpAnswer = await mediaServer.rcvVideoRetPeer?.processOffer(offer);
      logger.info('[MediaserverMiddleware][RecvVidRet] Sending VideoReturn SDP Answer:', sdpAnswer);

      if (store.getState().main.videoReturnAudioMuted) {
        mediaServer.rcvVideoRetPeer.muteRemoteAudio(true);
      }

      const offerH264Info = extractH264ProfileLevelId(offer);
      const answerH264Info = extractH264ProfileLevelId(sdpAnswer);
      logger.info(
        `[MediaserverMiddleware][RecvVidRet] Comparing H264 encoder conf:\nOffer: ${
          offerH264Info ? JSON.stringify(offerH264Info) : 'Not found !'
        }\nAnswer: ${answerH264Info ? JSON.stringify(answerH264Info) : 'Not found !'}`
      );

      if (
        offerH264Info &&
        answerH264Info &&
        offerH264Info['profile-level-id'] &&
        answerH264Info['profile-level-id'] &&
        offerH264Info['profile-level-id'] !== answerH264Info['profile-level-id']
      ) {
        logger.warn('>>>> H264 encoding difference detected !');
      }

      const newSdp: ISdp = {
        identifier: identifier,
        origin: 'client',
        type: 'answer',
        content: sdpAnswer,
      };

      store.dispatch(
        videoReturnReady({
          callId: identifier,
          payload: { sdp: newSdp },
          audioOnly: false,
        })
      );
    }
  } catch (error) {
    logger.error('[MediaserverMiddleware][RecvVidRet] Error creating peer:', error);
  }
}

export async function onVideoReturnReady(identifier: string, sdpAnswer: string, audioOnly: boolean) {
  const payload: ILiveGuestCommonPayload = {
    sdp: {
      identifier,
      origin: 'client',
      type: 'answer',
      content: sdpAnswer,
    },
  };

  store.dispatch(videoReturnReady({ callId: identifier, payload, audioOnly }));
}

export async function onNegotiateVideoReturn(call: ICall, sdp: ISdp) {
  try {
    if (mediaServer.rcvVideoRetPeer) {
      const offer = sdp.content!;
      const audioOnly = videoReturnIsAudioOnly(offer);
      logger.log('[MediaserverMiddleware][RecvVidRet] Negotiating with Sdp offer', offer);
      const sdpAnswer = await mediaServer.rcvVideoRetPeer!.processOffer(offer);
      logger.info('[MediaserverMiddleware][RecvVidRet] Sending VideoReturn SDP Answer:', sdpAnswer);
      await onVideoReturnReady(call.identifier, sdpAnswer, audioOnly);
    }
  } catch (err) {
    logger.error('[MediaserverMiddleware][RecvVidRet] Error negotiating:', err);
  }
}

export async function onIntercomReady(identifier: string, sdpAnswer: string) {
  const payload: ILiveGuestCommonPayload = {
    sdp: { identifier, origin: 'client', type: 'answer', content: sdpAnswer },
  };

  store.dispatch(intercomReady({ callId: identifier, payload }));
}

export async function onStartIntercom(call: ICall, iceServers: IIceServer[], sdp?: ISdp) {
  store.dispatch(intercomRequested());
  const identifier = call.identifier;

  mediaServer.rcvIntercomPeer = WebRtcPeer.WebRtcPeerRecvonly({
    loggingId: 'intercom',
    onicecandidate: (candidate: RTCIceCandidate) => {
      logger.info('[MediaserverMiddleware][RecvIntercom] Add Ice candidate for call ' + identifier, candidate);
      const payload: IIceCandidate = {
        identifier: identifier,
        origin: 'client',
        content: JSON.stringify(candidate.toJSON()),
        connectionType: 'receive',
      };
      store.dispatch(addIceCandidate({ callId: identifier, candidate: payload }));
    },
    onnegotiationneeded: () => {
      // TODO If we already are connected, we dispatch 'iceRestart' immediately
      if (store.getState().main.signalingStatus === SignalingStatus.connected) {
        store.dispatch(iceRestart({ call, mode: LiveGuestConnectionType.receive }));
      }
    },
    oniceconnectionstatechange: (status: string, usingTURN: boolean) => {
      logger.debug(
        `[MediaserverMiddleware][RecvIntercom] ICE connection state changed: ${status} - using TURN: ${usingTURN}`
      );
    },
    configuration: { iceServers },
  });

  try {
    await mediaServer.rcvIntercomPeer.start();
    if (intercomIceCandidatesQueue.length > 0) {
      while (intercomIceCandidatesQueue.length) {
        const candidate = intercomIceCandidatesQueue.shift()!;
        mediaServer.rcvIntercomPeer.addIceCandidate(candidate);
      }
    }

    const offer = sdp?.content;
    logger.log('[MediaserverMiddleware][RecvIntercom] Process Sdp offer', offer);

    if (store.getState().main.intercomStatus !== IntercomStatus.OFF) {
      const sdpAnswer = await mediaServer.rcvIntercomPeer?.processOffer(offer!);
      logger.info('[MediaserverMiddleware][RecvIntercom] Sending Intercom SDP Answer:', sdpAnswer);

      await onIntercomReady(identifier, sdpAnswer);
    }
  } catch (error) {
    logger.error('[MediaserverMiddleware][RecvIntercom] Error creating peer:', error);
  }
}

export function onStopVideoReturn() {
  stopVideoReturnPeer();
  store.dispatch(videoReturnStopped());
}

export function onStopIntercom() {
  stopIntercomPeer();
  store.dispatch(intercomStopped());
  if (store.getState().main.videoReturnAudioMuted) {
    // Unmute VR if remote sound is not muted
    store.dispatch(muteVideoReturnAudio(false)); // We mute video return intercom
  }
}

export function stopSenderPeer() {
  stopMonitorMicrophone();
  mediaServer.sendPeer!.stop();
}

export function stopVideoReturnPeer() {
  stopMonitorVideoReturnAudio();
  mediaServer.rcvVideoRetPeer?.stop();
}

export function stopIntercomPeer() {
  stopMonitorIntercomAudio();
  mediaServer.rcvIntercomPeer?.stop();
}
