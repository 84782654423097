import sdpTransform from 'sdp-transform';
import UAParser from 'ua-parser-js';

import { IClientInfo, IOs } from 'models/clientInfo.model';

import { IBrowser } from './../models/clientInfo.model';

declare global {
  interface Navigator {
    getUserMedia: Function;
    webkitGetUserMedia: Function;
    mozGetUserMedia: Function;
    msGetUserMedia: Function;
  }
}

const UA = window.navigator.userAgent;
const PARSER = new UAParser(UA);

export const browserIsWebRTCSupported = (): boolean => {
  return Boolean(
    navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection
  );
};

export const isWhitelisted = () => {
  // TODO
  return true;
};

export const videoReturnIsAudioOnly = (offer: string): boolean => {
  return !videoReturnIsFullyCompatible() || !videoReturnOfferHasVideo(offer);
};

export const videoReturnIsFullyCompatible = () => {
  // TODO
  return true;
};

export const videoReturnOfferHasVideo = (offer: string): boolean => {
  const parsedSdp = sdpTransform.parse(offer);
  return parsedSdp.media.findIndex((media) => media.type === 'video') !== -1;
};

export const isH264Supported = (sdpOffer: string): boolean => {
  return sdpOffer.search(/rtpmap:([0-9]+) H264/g) !== -1;
};

export const isMuteVideoSupported = () => {
  const parser = new UAParser();
  const { name: browser } = parser.getBrowser();
  const { name: os } = parser.getOS();
  return browser !== 'Safari' || os !== 'Mac OS';
};

export const shouldAvoidEchoCancellation = () => {
  //TODO
  return false;
};

export const shouldAvoidNoiseSuppression = () => shouldAvoidEchoCancellation();

function getBrowser(): IBrowser {
  const browserName = PARSER.getBrowser().name;
  switch (browserName) {
    case 'Chrome':
    case 'Firefox':
    case 'Safari':
    case 'Edge':
      return browserName;
    default:
      return 'Other';
  }
}

function getOs(): IOs {
  const osName = PARSER.getOS().name;
  switch (osName) {
    case 'Android':
    case 'iOS':
    case 'Windows':
    case 'Linux':
      return osName;
    case 'CentOS':
    case 'Debian':
    case 'Fedora':
    case 'Ubuntu':
      return 'Linux';
    case 'Mac OS':
      return 'macOS';
    default:
      return 'Other';
  }
}

export const buildClientInfo = (): IClientInfo => {
  return {
    browser: getBrowser(),
    browserVersion: PARSER.getBrowser().version || '',
    os: getOs(),
    osVersion: PARSER.getOS().version || '',
    browserIdentifier: localStorage.getItem('clientId') || '',
  };
};

export const extractH264ProfileLevelId = (sdp: string) => {
  const description = sdpTransform.parse(sdp);
  const videoMedia = description.media.find((currentMedia) => currentMedia.type === 'video');
  if (videoMedia && videoMedia.fmtp[0] && videoMedia.fmtp[0].config) {
    return sdpTransform.parseParams(videoMedia.fmtp[0].config);
  }
  return null;
};
