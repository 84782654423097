import { i18n, use } from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './locales/en';
import FR from './locales/fr';

declare global {
  interface Window {
    i18nInstance: i18n;
  }
}

export const liveguestNs = 'liveguest';

export type ILiveguestNs = typeof EN;

const i18NextInstance = use(i18nextBrowserLanguageDetector).use(initReactI18next);
i18NextInstance.init({
  ns: liveguestNs,
  defaultNS: liveguestNs,
  resources: {
    en: { liveguest: EN },
    fr: { liveguest: FR },
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],
    lookupQuerystring: 'lng',
    lookupLocalStorage: 'locale',
  },
  fallbackLng: ['en', 'fr'],
  interpolation: { escapeValue: false }, // not needed for react as it escapes by default
  debug: process.env.NODE_ENV === 'development',
});

window['i18nInstance'] = i18NextInstance;

export const i18nInstance = i18NextInstance;
