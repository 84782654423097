import { SoundMonitorKey } from 'models/mediaserver.model';
import { logger } from 'utils/logger';
import { SoundMeterFactory } from 'utils/SoundMeterFactory';
import { WebRtcPeer } from 'utils/WebRtcPeer';

window.AudioContext = window.AudioContext || window.webkitAudioContext;

interface IMediaServer {
  sendPeer?: WebRtcPeer;
  testTimeout?: number;
  rcvVideoRetPeer?: WebRtcPeer;
  rcvIntercomPeer?: WebRtcPeer;
  subscriptionRetryCount: number;
  localSoundMeter?: SoundMeterFactory;
  videoReturnSoundMeter?: SoundMeterFactory;
  intercomSoundMeter?: SoundMeterFactory;
}

export const mediaServer: IMediaServer = {
  subscriptionRetryCount: 0,
};

export const videoRetIceCandidatesQueue: RTCIceCandidate[] = [];
export const intercomIceCandidatesQueue: RTCIceCandidate[] = [];

export const forceTURN = localStorage.getItem('ice_type') === 'turn';
export const captureStats = localStorage.getItem('capture_stats');
export const videoReturnStats = localStorage.getItem('video_return_stats');

export const startMonitorMicrophone = () => {
  try {
    if (mediaServer.localSoundMeter) {
      mediaServer.localSoundMeter.stop();
    }
    mediaServer.localSoundMeter = new SoundMeterFactory(window.audioContext, SoundMonitorKey.local);
    mediaServer.localSoundMeter.connectToSource(mediaServer.sendPeer!.getLocalStream()!);
  } catch (e) {
    logger.error('Web Audio API not supported: ', e);
  }
};

export const stopMonitorMicrophone = () => {
  mediaServer.localSoundMeter?.stop();
};

export const startMonitorVideoReturnAudio = async () => {
  try {
    if (mediaServer.videoReturnSoundMeter) {
      mediaServer.videoReturnSoundMeter.stop();
    }
    mediaServer.videoReturnSoundMeter = new SoundMeterFactory(window.audioContext, SoundMonitorKey.videoReturn);
    mediaServer.videoReturnSoundMeter.connectToSource(mediaServer.rcvVideoRetPeer!.getRemoteStream()!);
  } catch (e) {
    logger.error('Web Audio API not supported: ', e);
  }
};

export const stopMonitorVideoReturnAudio = () => {
  mediaServer.videoReturnSoundMeter?.stop();
};

export const startMonitorIntercomAudio = async () => {
  try {
    if (mediaServer.intercomSoundMeter) {
      mediaServer.intercomSoundMeter.stop();
    }
    mediaServer.intercomSoundMeter = new SoundMeterFactory(window.audioContext, SoundMonitorKey.intercom);
    mediaServer.intercomSoundMeter.connectToSource(mediaServer.rcvIntercomPeer!.getRemoteStream()!);
  } catch (e) {
    logger.error('Web Audio API not supported: ', e);
  }
};

export const stopMonitorIntercomAudio = () => {
  mediaServer.intercomSoundMeter?.stop();
};
