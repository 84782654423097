import defaultEnvironment from './defaultEnvironment.json';

export interface IEnvironment {
  baseUrl: string;
  debugEnabled: boolean;
  default: boolean;
  type: string;
  version: {
    commit: string;
    name: string;
  };
}

declare global {
  interface Window {
    environment: IEnvironment;
  }
}

// Final environment is built using the defaultEnvironment first. Then we overwrite it with the proper environment, if available.
// When working locally, window.environment won't exist, so the defaultEnvironment will be used.
const environment = {
  ...defaultEnvironment,
  ...window.environment,
};

if (environment.default) {
  // eslint-disable-next-line no-console
  console.log(
    `%c ${String.fromCodePoint(0x2757)}%c using default vars! `,
    'color: #f5be32; line-height: 35px; font-weight: bold; padding: 5px 0; font-size: large;',
    'line-height: 35px; padding: 5px 0; font-size: large;'
  );
}

export default environment;
