export enum ErrorType {
  INVALID_TOKEN = 'INVALID_TOKEN',
  UNKNOWN = 'UNKNOWN',
  CONNECTION = 'CONNECTION',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  RECEIVER_NOT_READY = 'RECEIVER_NOT_READY',
  H264_NOT_SUPPORTED = 'H264_NOT_SUPPORTED',
  HOST_NOT_READY = 'HOST_NOT_READY',
  SESSION_ALREADY_USED = 'SESSION_ALREADY_USED',
}

export enum WarningType {
  AUDIO_NOT_FLOWING = 'AUDIO_NOT_FLOWING',
  VIDEO_NOT_FLOWING = 'VIDEO_NOT_FLOWING',
}

export enum SoundMonitorKey {
  local = 'local',
  videoReturn = 'videoReturn',
  intercom = 'intercom',
}

export interface TurnServerConfig {
  url: string;
  username: string;
  credentials: string;
}

export enum MediaType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export enum JanusElementName {
  PublisherHandle = 'PublisherHandle',
  StreamingHandle = 'StreamingHandle',
}

export interface MonitoringData {
  element: string;
  mediaType: MediaType;
  date: Date;
  state: string;
}

export interface MediaDeviceIds {
  audioInput?: string;
  audioOutput?: string;
  videoInput?: string;
}

export interface JanusPayload {
  payload: string;
}
