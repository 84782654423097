import { LiveGuestConnectionType } from '@hai/orion-constants';

import { ILiveGuestCommonPayload } from 'models/call.model';
import { IMediaDeviceInfo } from 'models/deviceInfo.model';
import { IIceCandidate, IIceConnectionInfo, ILiveGuestIceConnectionStatus } from 'models/iceServer.model';
import { JanusElementName } from 'models/mediaserver.model';
import {
  ACTIONS as CALLS_ACTIONS,
  addIceCandidate,
  callLeave,
  callNegotiate,
  callPrepare,
  sendIceConnectionInfo,
} from 'store/calls/calls.actions';
import { ACTIONS as MAIN_ACTIONS, prepareTimedOut } from 'store/main/main.actions';
import { SignalingStatus } from 'store/main/main.types';
import { stopIntercomPeer, stopSenderPeer, stopVideoReturnPeer } from 'store/middlewares/liveGuestWebsocket.utils';
import { isH264Supported, shouldAvoidEchoCancellation, shouldAvoidNoiseSuppression } from 'utils/global.utils';
import { logger } from 'utils/logger';
import { getVideoDimensions } from 'utils/video.utils';
import { WebRtcPeer } from 'utils/WebRtcPeer';

import {
  ACTIONS as MEDIA_SERVER_ACTIONS,
  browserPermissionChanged,
  devicesChanged,
  h264NotSupportedError,
  iceRestart,
  mediaTracksChanged,
  startPreview,
} from './mediaserver.actions';
import { captureStats, forceTURN, mediaServer, startMonitorMicrophone } from './mediaserver.utils';

export const mediaServerMiddleware = ({ dispatch, getState }) => {
  return (next) => async (action) => {
    next(action);

    if (action.type === MAIN_ACTIONS.GO_BACK) {
      const { call, testStatus } = action.payload;
      if (testStatus.host > 0) {
        // Call leave only if 'host' test step is OK
        await dispatch(callLeave(call.identifier));
      }
      dispatch(startPreview({ devices: { audioInput: undefined, videoInput: undefined }, call }));
    }

    if (action.type === MEDIA_SERVER_ACTIONS.INIT_CALL) {
      window.audioContext = new window.AudioContext();
      const { call, iceServers } = action.payload;
      logger.log('[MediaserverMiddleware] Initializing WebRtcPeer');

      logger.warn('[MediaserverMiddleware] Using ICE Servers: ', iceServers);

      if (forceTURN) {
        logger.warn('[ICE] Forcing TURN');
      }

      mediaServer.sendPeer = WebRtcPeer.WebRtcPeerSendonly({
        iceRestartEnabled: true,
        onicecandidate: (candidate: RTCIceCandidate) => {
          const payload: IIceCandidate = {
            identifier: call.identifier,
            origin: 'client',
            content: JSON.stringify(candidate.toJSON()),
            connectionType: 'send',
          };
          dispatch(addIceCandidate({ callId: call.identifier, candidate: payload }));
        },
        ondevicechange: (devices: IMediaDeviceInfo[]) => {
          startMonitorMicrophone();
          dispatch(devicesChanged(devices));
        },
        onpermissionchange: (granted, error) => dispatch(browserPermissionChanged({ granted, error })),
        onnegotiationneeded: (type: 'ICE' | 'MEDIA') => {
          // If we already are connected, we dispatch 'iceRestart' immediately
          if (type === 'ICE' && getState().main.signalingStatus === SignalingStatus.connected) {
            dispatch(
              iceRestart({
                call,
                mode: LiveGuestConnectionType.send,
                offer: mediaServer.sendPeer!.getIceNegotiationOffer()!,
              })
            );
          }
          if (type === 'MEDIA' && getState().main.signalingStatus === SignalingStatus.connected) {
            dispatch(
              mediaTracksChanged({
                call,
                mode: LiveGuestConnectionType.send,
                offer: mediaServer.sendPeer!.getMediaNegotiationOffer()!,
              })
            );
          }
        },
        oniceconnectionstatechange: (status: ILiveGuestIceConnectionStatus, usingTURN: boolean) => {
          const connectionInfo: IIceConnectionInfo = {
            identifier: call.identifier,
            origin: 'client',
            connectionType: 'send',
            type: mediaServer.sendPeer?.usingTURN ? 'turn' : 'default',
            status: status,
          };
          dispatch(sendIceConnectionInfo({ callId: call.identifier, connectionInfo }));
        },
        configuration: { iceServers, iceTransportPolicy: forceTURN ? 'relay' : 'all' },
      });
      if (captureStats != null) {
        const stats = captureStats.indexOf(',') !== -1 ? captureStats.split(',') : [captureStats];
        logger.warn('[MediaserverMiddleware][Send] >>> Will log stats: ', stats);
        mediaServer.sendPeer.startLoggingStats(3000, stats);
      }
      dispatch(startPreview({ devices: { audioInput: undefined, videoInput: undefined }, call }));
    }

    if (action.type === MEDIA_SERVER_ACTIONS.START_PREVIEW) {
      const devices = action.payload.devices;
      const call = action.payload.call;
      let aspectRatio: number = parseInt(call.aspectRatio.split(':')[0]) / parseInt(call.aspectRatio.split(':')[1]);
      aspectRatio = isNaN(aspectRatio) ? 1.7777777777777777 : aspectRatio;
      const [width, height] = getVideoDimensions(call.standard);
      const baseAudioConstraints: MediaTrackConstraints = {};
      if (shouldAvoidEchoCancellation()) {
        baseAudioConstraints.echoCancellation = false;
      }
      if (shouldAvoidNoiseSuppression()) {
        baseAudioConstraints.noiseSuppression = false;
      }
      try {
        await mediaServer.sendPeer!.start({
          audio: { ...baseAudioConstraints, deviceId: devices.audioInput ? { exact: devices.audioInput } : undefined },
          video: {
            deviceId: devices.videoInput ? { exact: devices.videoInput } : undefined,
            aspectRatio: { exact: aspectRatio },
            width: { ideal: width },
            height: { ideal: height },
            frameRate: { ideal: 30 },
          },
        });
        startMonitorMicrophone();
        mediaServer.sendPeer!.attachLocalElement(document.getElementById('settings-local-stream') as HTMLVideoElement);
      } catch (error) {
        logger.error('[MediaserverMiddleware] Error starting peer:', error);
      }
    }

    if (action.type === CALLS_ACTIONS.CALL_PREPARE_INIT) {
      try {
        const sdpOffer = await mediaServer.sendPeer!.generateOffer();
        if (!isH264Supported(sdpOffer!)) {
          dispatch(h264NotSupportedError());
        } else {
          dispatch(callPrepare({ callId: action.payload, sdpOffer })).then(() => {
            mediaServer.testTimeout = window.setTimeout(() => {
              dispatch(prepareTimedOut());
            }, 10_000);
          });
        }
      } catch (error) {
        logger.error('[MediaserverMiddleware][Send] Error generating offer:', error);
      }
    }

    if (action.type === MEDIA_SERVER_ACTIONS.RECEIVED_JANUS_MEDIA_EVENT) {
      const payload = JSON.parse(action.payload.elementName);
      if (
        payload.elementName === JanusElementName.PublisherHandle &&
        payload.state === 'FLOWING' &&
        ((payload.mediaType.toLowerCase() === 'video' && getState().main.testStatus.audio) ||
          (payload.mediaType.toLowerCase() === 'audio' && getState().main.testStatus.video))
      ) {
        clearTimeout(mediaServer.testTimeout);
      }
    }

    if (action.type === CALLS_ACTIONS.CALL_JOIN + '/fulfilled') {
      mediaServer.sendPeer?.attachLocalElement(document.getElementById('local-stream') as HTMLVideoElement);
    }

    if (action.type === CALLS_ACTIONS.CALL_LEAVE + '/fulfilled') {
      stopSenderPeer();
      stopVideoReturnPeer();
      stopIntercomPeer();
    }

    if (action.type === MEDIA_SERVER_ACTIONS.ICE_RESTART || action.type === MEDIA_SERVER_ACTIONS.MEDIA_TRACKS_CHANGED) {
      const { call, mode, offer } = action.payload;

      let payload: ILiveGuestCommonPayload | undefined;
      if (mode === 'send') {
        payload = {
          sdp: {
            identifier: call.identifier,
            origin: 'client',
            type: 'offer',
            content: offer,
          },
        };
      }

      dispatch(callNegotiate({ callId: call.identifier, payload }));
    }

    if (action.type === MAIN_ACTIONS.MUTE_AUDIO) {
      mediaServer.sendPeer?.muteLocalAudio(action.payload);
    }

    if (action.type === MAIN_ACTIONS.MUTE_VIDEO) {
      mediaServer.sendPeer?.muteLocalVideo(action.payload);
    }

    if (action.type === MAIN_ACTIONS.MUTE_INTERCOM_AUDIO) {
      mediaServer.rcvIntercomPeer?.muteRemoteAudio(action.payload);
    }

    if (action.type === MAIN_ACTIONS.MUTE_VIDEO_RETURN_AUDIO) {
      mediaServer.rcvVideoRetPeer?.muteRemoteAudio(action.payload);
    }
  };
};
