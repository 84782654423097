import { IMediaDeviceInfo } from 'models/deviceInfo.model';
import { ErrorType, TurnServerConfig, WarningType } from 'models/mediaserver.model';

// Reducer

export interface IMainState {
  ready: boolean;
  status: MainStatus;
  testStatus: TestStatus;
  jwt: string;
  videoReturnStatus: VideoReturnStatus;
  intercomStatus: IntercomStatus;
  turnConfig: TurnServerConfig | null;
  errorType: ErrorType | null;
  warningTypes: WarningType[];
  errorDetails: string | null;
  audioMuted: boolean;
  videoReturnAudioMuted: boolean;
  intercomAudioMuted: boolean;
  videoMuted: boolean;
  devices: IMediaDeviceInfo[];
  browserPermissionGranted: boolean;
  browserPermissionError: string | null;
  signalingStatus: SignalingStatus;
}

export type CallLayout = string;

export interface PasswordValues {
  password: string;
}

// Enums
export enum MainStatus {
  IDLE = 'IDLE',
  ERROR = 'ERROR',
  AUTHENTICATED = 'AUTHENTICATED', // Password correct, or call not protected
  TESTING = 'TESTING', // Called "prepare"
  TEST_OK = 'TEST_OK', // Received expected janus events
  TEST_FAILED = 'TEST_FAILED', // No response to "prepare" in 15sec
  STARTING = 'STARTING', // Call to "join" successful
  LIVE = 'LIVE', // Got response to "join" via liveservice
  FINISHED = 'FINISHED',
  HUNG_UP = 'HUNG_UP',
}

export interface TestStatus {
  // -1 = test failed, 0 = not tested, 1 = warning, 2 = test succeed
  webRTC: number;
  host: number;
  connectivity: number;
  video: number;
  audio: number;
}

export enum SignalingStatus {
  connected = 'connected',
  connecting = 'connecting',
  disconnected = 'disconnected',
}

export enum VideoReturnStatus {
  OFF = 'OFF',
  STARTING = 'STARTING',
  STARTED_AUDIO_VIDEO = 'STARTED_AUDIO_VIDEO',
  STARTED_AUDIO_ONLY = 'STARTED_AUDIO_ONLY',
}

export enum IntercomStatus {
  OFF = 'OFF',
  STARTING = 'STARTING',
  STARTED = 'STARTED',
}
