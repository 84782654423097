import { IEnvironment } from 'environment/environment';

export function getHttpApiUrl(environment: IEnvironment, serviceName: string) {
  return `${environment.baseUrl || window.location.origin}/${serviceName}`;
}

const replaceHttpToWs = (url?: string) => {
  if (url) {
    return url.startsWith('http:') ? url.replace('http:', 'ws:') : url.replace('https:', 'wss:');
  }
};

export const getWsApiUrl = (environment: IEnvironment, serviceName: string) => {
  return replaceHttpToWs(`${environment.baseUrl || window.location.origin}/${serviceName}`);
};
