export function getVideoDimensions(res: '720p' | '1080p' | (string & {})): [number, number] {
  switch (res) {
    case '720p':
      return [1280, 720];
    case '1080p':
      return [1920, 1080];
    default:
      return [480, 720];
  }
}
