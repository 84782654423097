import { createReducer } from '@reduxjs/toolkit';

import { ErrorType, JanusElementName, WarningType } from 'models/mediaserver.model';
import {
  authenticateCall,
  callJoin,
  callLeave,
  callPrepare,
  intercomRequested,
  videoReturnReady,
  videoReturnRequested,
} from 'store/calls/calls.actions';
import {
  browserPermissionChanged,
  devicesChanged,
  h264NotSupportedError,
  intercomStopped,
  receivedError,
  receivedJanusMediaEvent,
  receivedJanusWebRtcUpEvent,
  startPreview,
} from 'store/mediaserver/mediaserver.actions';
import { videoReturnIsFullyCompatible } from 'utils/global.utils';

import {
  goBack,
  hungUp,
  liveServiceReceivedError,
  muteAudio,
  muteIntercomAudio,
  muteVideo,
  muteVideoReturnAudio,
  prepareTimedOut,
  receivedJoinResponse,
  receivedPrepareResponse,
  receivedSessionAlreadyUsed,
  receivedStreamhubNotReady,
  subscribeLiveServiceError,
  subscribeLiveServiceRequest,
  subscribeLiveServiceSuccess,
  videoReturnStopped,
} from './main.actions';
import { IMainState, IntercomStatus, MainStatus, SignalingStatus, TestStatus, VideoReturnStatus } from './main.types';

const initialTestStatus: TestStatus = {
  webRTC: 0,
  host: 0,
  connectivity: 0,
  video: 0,
  audio: 0,
};

const INITIAL_STATE: IMainState = {
  ready: false,
  status: MainStatus.IDLE,
  testStatus: initialTestStatus,
  jwt: '',
  videoReturnStatus: VideoReturnStatus.OFF,
  intercomStatus: IntercomStatus.OFF,
  turnConfig: null,
  warningTypes: [],
  errorType: null,
  errorDetails: null,
  audioMuted: false,
  videoReturnAudioMuted: false,
  intercomAudioMuted: false,
  videoMuted: false,
  devices: [],
  browserPermissionGranted: false,
  browserPermissionError: null,
  signalingStatus: SignalingStatus.disconnected,
};

export const mainReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(authenticateCall.pending, (state) => {
    state.errorType = null;
    state.status = MainStatus.IDLE;
  });
  builder.addCase(authenticateCall.rejected, (state, action) => {
    state.errorType = action.error && action.error.code === '2000' ? ErrorType.INVALID_PASSWORD : ErrorType.UNKNOWN;
    state.status = MainStatus.ERROR;
  });
  builder.addCase(authenticateCall.fulfilled, (state, action) => {
    state.jwt = action.payload;
    state.status = MainStatus.AUTHENTICATED;
  });

  builder.addCase(devicesChanged, (state, action) => {
    state.devices = action.payload;
  });

  builder.addCase(callPrepare.rejected, (state) => {
    state.status = MainStatus.AUTHENTICATED;
    state.errorType = ErrorType.CONNECTION;
  });

  builder.addCase(callJoin.rejected, (state) => {
    state.status = MainStatus.TEST_OK;
    state.errorType = ErrorType.CONNECTION;
  });

  builder.addCase(callJoin.fulfilled, (state) => {
    state.errorType = null;
    state.warningTypes = [];
    state.status = state.status === MainStatus.LIVE ? state.status : MainStatus.STARTING;
    state.audioMuted = false;
    state.videoMuted = false;
  });

  builder.addCase(callLeave.rejected, (state) => {
    state.status = MainStatus.AUTHENTICATED;
    state.errorType = ErrorType.CONNECTION;
  });

  builder.addCase(callLeave.fulfilled, (state) => {
    state.status = MainStatus.FINISHED;
    state.videoReturnStatus = VideoReturnStatus.OFF;
    state.intercomStatus = IntercomStatus.OFF;
    state.warningTypes = [];
  });

  builder.addCase(muteAudio, (state, action) => {
    state.audioMuted = action.payload;
  });

  builder.addCase(videoReturnRequested, (state) => {
    state.videoReturnStatus = VideoReturnStatus.STARTING;
  });

  builder.addCase(muteVideoReturnAudio, (state, action) => {
    state.videoReturnAudioMuted = action.payload;
  });

  builder.addCase(muteIntercomAudio, (state, action) => {
    state.intercomAudioMuted = action.payload;
  });

  builder.addCase(muteVideo, (state, action) => {
    state.videoMuted = action.payload;
  });

  builder.addCase(startPreview, (state) => {
    state.browserPermissionGranted = false;
  });

  builder.addCase(browserPermissionChanged, (state, action) => {
    state.browserPermissionGranted = action.payload.granted;
    state.browserPermissionError = action.payload.error || null;
  });

  builder.addCase(videoReturnReady.pending, (state) => {
    state.videoReturnStatus = VideoReturnStatus.STARTING;
  });

  builder.addCase(videoReturnReady.rejected, (state) => {
    state.videoReturnStatus = VideoReturnStatus.OFF;
  });

  builder.addCase(videoReturnReady.fulfilled, (state, action) => {
    state.videoReturnStatus = action.payload
      ? VideoReturnStatus.STARTED_AUDIO_ONLY
      : VideoReturnStatus.STARTED_AUDIO_VIDEO;
  });

  builder.addCase(receivedPrepareResponse, (state) => {
    state.testStatus.host = 2;
  });

  builder.addCase(receivedJoinResponse, (state, action) => {
    state.status = MainStatus.LIVE;
    state.testStatus = initialTestStatus;
  });

  builder.addCase(videoReturnStopped, (state) => {
    state.videoReturnStatus = VideoReturnStatus.OFF;
  });

  builder.addCase(intercomStopped, (state) => {
    state.intercomStatus = IntercomStatus.OFF;
  });

  builder.addCase(intercomRequested, (state) => {
    state.intercomStatus = IntercomStatus.STARTING;

    //   case INTERCOM_READY_SUCCESS:
    //     return {
    //       ...state,
    //       intercomStatus: IntercomStatus.STARTED,
    //       remoteAudioMuted: false,
    //     };
    //   case INTERCOM_READY_ERROR:
    //     return {
    //       ...state,
    //       intercomStatus: IntercomStatus.OFF,
    //     };
  });

  builder.addCase(callPrepare.fulfilled, (state, action) => {
    state.status = MainStatus.TESTING;
    state.errorType = null;
    state.testStatus.host = 2;
    state.testStatus.webRTC = !videoReturnIsFullyCompatible() ? 1 : 2;
  });

  builder.addCase(subscribeLiveServiceRequest, (state) => {
    state.signalingStatus = SignalingStatus.connecting;
  });

  builder.addCase(subscribeLiveServiceSuccess, (state) => {
    state.signalingStatus = SignalingStatus.connected;
  });

  builder.addCase(subscribeLiveServiceError, (state) => {
    state.signalingStatus = SignalingStatus.disconnected;
  });

  builder.addCase(prepareTimedOut, (state, _action) => {
    state.testStatus.connectivity = state.testStatus.connectivity > 0 ? state.testStatus.connectivity : -1;
    state.testStatus.audio = -1;
    state.testStatus.video = -1;
    state.status = MainStatus.TEST_FAILED;
  });

  builder.addCase(goBack, (state) => {
    state.status = MainStatus.AUTHENTICATED;
    state.testStatus = initialTestStatus;
    state.errorType = null;
  });

  builder.addCase(h264NotSupportedError, (state) => {
    state.status = MainStatus.TEST_FAILED;
    state.errorType = ErrorType.H264_NOT_SUPPORTED;
    state.testStatus.webRTC = -1;
  });

  builder.addCase(receivedStreamhubNotReady, (state) => {
    state.status = MainStatus.TEST_FAILED;
    state.errorType = ErrorType.HOST_NOT_READY;
    state.testStatus.host = -1;
  });

  builder.addCase(receivedSessionAlreadyUsed, (state) => {
    if (state.status === MainStatus.TESTING) {
      state.status = MainStatus.TEST_FAILED;
      state.errorType = ErrorType.SESSION_ALREADY_USED;
      state.testStatus.host = -1;
    }
  });

  builder.addCase(receivedJanusWebRtcUpEvent, (state, action) => {
    const element = JSON.parse(action.payload.elementName);
    if (state.status === MainStatus.TEST_FAILED || element.elementName !== JanusElementName.PublisherHandle) {
      return;
    }

    state.testStatus.host = 2;
    state.testStatus.connectivity = action.payload.usingTURN ? 1 : 2;
  });

  builder.addCase(receivedJanusMediaEvent, (state, action) => {
    const element = JSON.parse(action.payload.elementName);
    if (state.status === MainStatus.TEST_FAILED || element.elementName !== JanusElementName.PublisherHandle) {
      return;
    }

    if (state.status === MainStatus.TESTING) {
      if (element.state === 'FLOWING') {
        state.testStatus.host = 2;
        state.testStatus[element.mediaType.toLowerCase()] = 2;
        if (
          state.testStatus.connectivity > 0 &&
          state.testStatus.webRTC > 0 &&
          state.testStatus.video > 0 &&
          state.testStatus.audio > 0
        ) {
          state.status = MainStatus.TEST_OK;
        }
      } else if (element.state === 'NOT_FLOWING') {
        state.status = MainStatus.TEST_FAILED;
      }
    } else {
      const newWarning = `${element.mediaType}_NOT_FLOWING` as WarningType;
      if (element.state === 'FLOWING') {
        state.warningTypes = state.warningTypes.filter((warning) => warning !== newWarning);
      } else if (element.state === 'NOT_FLOWING') {
        state.warningTypes.push(newWarning);
      }
    }
  });

  builder.addCase(hungUp, (state) => {
    if (
      state.status === MainStatus.LIVE ||
      state.status === MainStatus.STARTING ||
      state.status === MainStatus.TEST_OK ||
      state.status === MainStatus.TESTING
    ) {
      state.status = MainStatus.HUNG_UP;
      state.videoReturnStatus = VideoReturnStatus.OFF;
      state.intercomStatus = IntercomStatus.OFF;
      state.warningTypes = [];
    }
  });

  builder.addCase(liveServiceReceivedError, (state) => {
    state.errorType = ErrorType.CONNECTION;
  });

  builder.addCase(receivedError, (state, action) => {
    switch (state.status) {
      case MainStatus.TESTING:
        state.status = MainStatus.TEST_FAILED;
        state.errorType = ErrorType.UNKNOWN;
        state.errorDetails = action.payload;
        break;
      case MainStatus.STARTING:
        state.status = MainStatus.TEST_OK;
        state.errorType = ErrorType.UNKNOWN;
        state.errorDetails = action.payload;
    }
  });
});
