import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    audioContext: AudioContext;
    webkitAudioContext: AudioContext;
  }
}

const rootElement = document.getElementById('root');

const root = createRoot(rootElement!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// print splash message to browser console
const hearth = String.fromCodePoint(0x2665);
const lamda = String.fromCodePoint(0x039b);
const shark = String.fromCodePoint(0x1f988);
// eslint-disable-next-line no-console
console.log(
  `\n%c Made with %c${hearth}%c by H${lamda}IVISION %c${shark} \n`,
  'color: #00a1e1; line-height: 35px; font-weight: bold; padding: 5px 0; font-size: x-large;',
  'color: #ea4645; line-height: 35px; padding: 5px 0; font-size: x-large;',
  'color: #00a1e1; line-height: 35px; font-weight: bold; padding: 5px 0; font-size: x-large;',
  'color: transparent; text-shadow: 0 0 0 #00a1e1; line-height: 35px; padding: 5px 0; font-size: x-large;'
);
