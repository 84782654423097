import { LiveGuestConnectionType } from '@hai/orion-constants';
import { createAction } from '@reduxjs/toolkit';

import { ICall } from 'models/call.model';
import { IMediaDeviceInfo } from 'models/deviceInfo.model';
import { IIceServer } from 'models/iceServer.model';
import { MediaDeviceIds } from 'models/mediaserver.model';

const PREFIX = 'mediaServer';

export const ACTIONS = {
  START_PREVIEW: `${PREFIX}/startPreview`,
  DEVICE_CHANGED: `${PREFIX}/deviceChanged`,
  INIT_CALL: `${PREFIX}/initCall`,
  ICE_RESTART: `${PREFIX}/iceRestart`,
  ICE_CONNECTION_CHANGE: `${PREFIX}/iceConnectionChange`,
  MEDIA_TRACKS_CHANGED: `${PREFIX}/mediaTracksChanged`,
  BROWSER_PERMISSION_CHANGED: `${PREFIX}/browserPermissionChanged`,
  RECEIVED_JANUS_WEB_RTC_UP_EVENT: `${PREFIX}/receivedJanusWebRtcUpEvent`,
  RECEIVED_JANUS_MEDIA_EVENT: `${PREFIX}/receivedJanusMediaEvent`,
  RECEIVED_JANUS_TRICKLE_EVENT: `${PREFIX}/receivedJanusTrickleEvent`,
  H264_NOT_SUPPORTED_ERROR: `${PREFIX}/h264NotSupportedError`,
  INTERCOM_STOPPED: `${PREFIX}/intercomStopped`,
  RECEIVED_ERROR: `${PREFIX}/receivedError`,
};

export const receivedError = createAction<string>(ACTIONS.RECEIVED_ERROR);

export const initCall = createAction<{ call: ICall; iceServers: IIceServer[] }>(ACTIONS.INIT_CALL);

export const browserPermissionChanged = createAction<{ granted: boolean; error?: string }>(
  ACTIONS.BROWSER_PERMISSION_CHANGED
);

export const startPreview = createAction<{ devices: MediaDeviceIds; call: ICall }>(ACTIONS.START_PREVIEW);

export const devicesChanged = createAction<IMediaDeviceInfo[]>(ACTIONS.DEVICE_CHANGED);

export const h264NotSupportedError = createAction<void>(ACTIONS.H264_NOT_SUPPORTED_ERROR);

export const receivedJanusWebRtcUpEvent = createAction<{ elementName: string; usingTURN?: boolean }>(
  ACTIONS.RECEIVED_JANUS_WEB_RTC_UP_EVENT
);

export const receivedJanusMediaEvent = createAction<{ elementName: string; usingTURN?: boolean }>(
  ACTIONS.RECEIVED_JANUS_MEDIA_EVENT
);

export const receivedJanusTrickleEvent = createAction<string>(ACTIONS.RECEIVED_JANUS_TRICKLE_EVENT);

// export const liveServiceReceivedError = (identifier: string, error: RpcError): LiveServiceReceivedErrorAction => {
//   return {
//     type: LIVE_SERVICE_RECEIVED_ERROR,
//     identifier,
//     error,
//   };
// };

export const intercomStopped = createAction<void>(ACTIONS.INTERCOM_STOPPED);

export const iceRestart = createAction<{ call: ICall; mode: LiveGuestConnectionType; offer?: string }>(
  ACTIONS.ICE_RESTART
);

export const mediaTracksChanged = createAction<{ call: ICall; mode: LiveGuestConnectionType; offer?: string }>(
  ACTIONS.MEDIA_TRACKS_CHANGED
);

export const iceConnectionChange = createAction<{ state: RTCIceConnectionState; peerConnectionType: 'recv' | 'send' }>(
  ACTIONS.ICE_CONNECTION_CHANGE
);
