import { LiveGuestOrigin, SdpType } from '@hai/orion-constants';
import { createAction } from '@reduxjs/toolkit';

import {
  authenticate,
  fetchCallByIdentifier,
  fetchIceServers,
  intercomReady as intercomReadyHttp,
  join,
  leave,
  negotiate,
  negotiateVideoReturn,
  prepare,
  putIceCandidate,
  sendIceConnection,
  videoReturnReady as videoReturnReadyHttp,
} from 'http/calls';
import { ICall, ILiveGuestCommonPayload } from 'models/call.model';
import { IIceCandidate, IIceConnectionInfo, IIceServer } from 'models/iceServer.model';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { muteVideoReturnAudio } from 'store/main/main.actions';
import {
  mediaServer,
  startMonitorIntercomAudio,
  startMonitorVideoReturnAudio,
} from 'store/mediaserver/mediaserver.utils';
import { buildClientInfo } from 'utils/global.utils';
import { logger } from 'utils/logger';

import { iceServers } from '../../STUNServers';

const PREFIX = 'calls';

export const ACTIONS = {
  GET_CALL: `${PREFIX}/getCall`,
  AUTHENTICATE_CALL: `${PREFIX}/authenticateCall`,
  GET_ICE_SERVERS: `${PREFIX}/getIceServers`,
  CALL_PREPARE_INIT: `${PREFIX}/callPrepareInit`,
  CALL_PREPARE: `${PREFIX}/callPrepare`,
  CALL_JOIN: `${PREFIX}/callJoin`,
  CALL_LEAVE: `${PREFIX}/callLeave`,
  VIDEO_RETURN_REQUESTED: `${PREFIX}/videoReturnRequested`,
  VIDEO_RETURN_READY: `${PREFIX}/videoReturnReady`,
  INTERCOM_READY: `${PREFIX}/intercomReady`,
  INTERCOM_REQUESTED: `${PREFIX}/intercomRequested`,
  ADD_ICE_CANDIDATE: `${PREFIX}/addIceCandidate`,
  SEND_ICE_CONNECTION_INFO: `${PREFIX}/sendIceConnectionInfo`,
  CALL_NEGOTIATE: `${PREFIX}/callNegotiate`,
};

export const getCall = createAppAsyncThunk<ICall, string>(ACTIONS.GET_CALL, async (data) => {
  try {
    return await fetchCallByIdentifier(data);
  } catch (error) {
    logger.error('getCall error', error);
    throw error;
  }
});

export const authenticateCall = createAppAsyncThunk<string, { callId: string; password?: string }>(
  ACTIONS.AUTHENTICATE_CALL,
  async (data, { dispatch }) => {
    try {
      const response = await authenticate(data.callId, data.password);
      dispatch(getIceServers({ callId: data.callId, token: response.token }));
      return response.token;
    } catch (error) {
      logger.error('authenticateCall error', error);
      throw error;
    }
  }
);

export const getIceServers = createAppAsyncThunk<IIceServer[], { callId: string; token?: string }>(
  ACTIONS.GET_ICE_SERVERS,
  async (data, { getState }) => {
    const forceSTUN = localStorage.getItem('ice_type') === 'stun';
    if (forceSTUN) {
      logger.warn('[ICE] Forcing STUN', iceServers);
      return iceServers;
    } else {
      try {
        const token = data.token ?? getState().main.jwt;
        return (await fetchIceServers(data.callId, token)).iceServers;
      } catch (error) {
        logger.error('Error getting Server configurations:', error);
        logger.warn('Using default ones', iceServers);
        return iceServers;
      }
    }
  }
);

export const callJoin = createAppAsyncThunk<void, string>(ACTIONS.CALL_JOIN, async (data, { getState }) => {
  try {
    return await join(data, getState().main.jwt);
  } catch (error) {
    logger.error('Call join error', error);
    throw error;
  }
});

export const addIceCandidate = createAppAsyncThunk<void, { callId: string; candidate: IIceCandidate }>(
  ACTIONS.ADD_ICE_CANDIDATE,
  async (data, { getState }) => {
    try {
      await putIceCandidate(data.callId, data.candidate, getState().main.jwt);
    } catch (error) {
      logger.error('addIceCandidate error', error);
      throw error;
    }
  }
);

export const sendIceConnectionInfo = createAppAsyncThunk<void, { callId: string; connectionInfo: IIceConnectionInfo }>(
  ACTIONS.SEND_ICE_CONNECTION_INFO,
  async (data, { getState }) => {
    try {
      await sendIceConnection(data.callId, data.connectionInfo, getState().main.jwt);
    } catch (error) {
      logger.error('sendIceConnectionInfo error', error);
      throw error;
    }
  }
);

export const callPrepareInit = createAction<string>(ACTIONS.CALL_PREPARE_INIT);

export const callPrepare = createAppAsyncThunk<void, { callId: string; sdpOffer?: string }>(
  ACTIONS.CALL_PREPARE,
  async (data, { getState }) => {
    const payload = {
      sdp: {
        identifier: data.callId,
        type: SdpType.offer,
        origin: LiveGuestOrigin.client,
        content: data.sdpOffer,
      },
      clientInfo: buildClientInfo(),
    };

    try {
      await prepare(data.callId, payload, getState().main.jwt);
    } catch (error) {
      logger.error('callPrepare error', error);
      throw error;
    }
  }
);

export const callNegotiate = createAppAsyncThunk<void, { callId: string; payload?: ILiveGuestCommonPayload }>(
  ACTIONS.CALL_NEGOTIATE,
  async (data, { getState }) => {
    try {
      // in order to keep a single action to handle both calls as it was previously
      if (data.payload) {
        await negotiate(data.callId, data.payload, getState().main.jwt);
      } else {
        await negotiateVideoReturn(data.callId, getState().main.jwt);
      }
    } catch (error) {
      logger.error('callNegotiate error', error);
      throw error;
    }
  }
);

export const callLeave = createAppAsyncThunk<void, string>(ACTIONS.CALL_LEAVE, async (data, { getState }) => {
  try {
    return await leave(data, getState().main.jwt);
  } catch (error) {
    logger.error('callLeave error', error);
    throw error;
  }
});

export const videoReturnRequested = createAction<void>(ACTIONS.VIDEO_RETURN_REQUESTED);

export const videoReturnReady = createAppAsyncThunk<
  boolean,
  { callId: string; payload: ILiveGuestCommonPayload; audioOnly: boolean }
>(ACTIONS.VIDEO_RETURN_READY, async (data, { getState }) => {
  try {
    await videoReturnReadyHttp(data.callId, data.payload, getState().main.jwt);
    startMonitorVideoReturnAudio();
    if (getState().main.intercomStatus === 'STARTED') {
      // Muting VR if intercom is already ON
      mediaServer.rcvVideoRetPeer?.muteRemoteAudio(true);
    }
    const remoteVideoElement = document.getElementById('remote-stream') as HTMLVideoElement;
    mediaServer.rcvVideoRetPeer?.attachRemoteElement(remoteVideoElement);

    return data.audioOnly;
  } catch (error) {
    logger.error('videoReturnReady error', error);
    throw error;
  }
});

export const intercomReady = createAppAsyncThunk<void, { callId: string; payload: ILiveGuestCommonPayload }>(
  ACTIONS.INTERCOM_READY,
  async (data, { getState, dispatch }) => {
    try {
      const res = await intercomReadyHttp(data.callId, data.payload, getState().main.jwt);
      dispatch(muteVideoReturnAudio(true));
      startMonitorIntercomAudio();
      const remoteVideoElement = document.getElementById('intercom-stream') as HTMLVideoElement;
      mediaServer.rcvIntercomPeer?.attachRemoteElement(remoteVideoElement);
      return res;
    } catch (error) {
      logger.error('intercomReady error', error);
      throw error;
    }
  }
);

export const intercomRequested = createAction<void>(ACTIONS.INTERCOM_REQUESTED);
