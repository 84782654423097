import { conditionalLogger } from '@hai/hub-common-portal';

const showLogs = true; // TODO if we want to show logs conditionally, we need to visit this
const basicLogger = conditionalLogger(showLogs);

/**
 * New proxy to log errors without some props.
 *
 * If you want to use the old logger, just export the basic logger.
 */
export const logger = new Proxy(basicLogger, {
  get: function (target: Console, prop: keyof Console, receiver) {
    if (prop === 'error') {
      return (...args: any[]) => {
        const transformedArgs = args.map((arg) => {
          return arg;
        });
        target[prop](...transformedArgs);
      };
    }
    return target[prop];
  },
});
