import { createAction } from '@reduxjs/toolkit';

import { ICall } from 'models/call.model';

import { TestStatus } from './main.types';

const PREFIX = 'main';

export const ACTIONS = {
  GO_BACK: `${PREFIX}/goBack`,
  MUTE_AUDIO: `${PREFIX}/muteAudio`,
  MUTE_VIDEO: `${PREFIX}/muteVideo`,
  MUTE_VIDEO_RETURN_AUDIO: `${PREFIX}/muteVideoReturnAudio`,
  MUTE_INTERCOM_AUDIO: `${PREFIX}/muteIntercomAudio`,
  VIDEO_RETURN_READY: `${PREFIX}/videoReturn`,
  VIDEO_RETURN_STOPPED: `${PREFIX}/videoReturnStopped`,
  SUBSCRIBE_LIVE_SERVICE_REQUEST: `${PREFIX}/subscribeLiveServiceRequest`,
  SUBSCRIBE_LIVE_SERVICE_SUCCESS: `${PREFIX}/subscribeLiveServiceSuccess`,
  SUBSCRIBE_LIVE_SERVICE_ERROR: `${PREFIX}/subscribeLiveServiceError`,
  PREPARE_TIMED_OUT: `${PREFIX}/prepareTimedOut`,
  RECEIVED_STREAMHUB_NOT_READY: `${PREFIX}/receivedStreamhubNotReady`,
  RECEIVED_SESSION_ALREADY_USED: `${PREFIX}/receivedSessionAlreadyUsed`,
  RECEIVED_PREPARE_RESPONSE: `${PREFIX}/receivedPrepareResponse`,
  RECEIVED_JOIN_RESPONSE: `${PREFIX}/receivedJoinResponse`,
  RECEIVED_LEAVE_RESPONSE: `${PREFIX}/receivedLeaveResponse`,
  HUNG_UP: `${PREFIX}/hungUp`,
  LIVE_SERVICE_RECEIVED_ERROR: `${PREFIX}/liveServiceReceivedError`,
};

export const liveServiceReceivedError = createAction<void>(ACTIONS.LIVE_SERVICE_RECEIVED_ERROR);

export const receivedSessionAlreadyUsed = createAction<void>(ACTIONS.RECEIVED_SESSION_ALREADY_USED);

export const receivedStreamhubNotReady = createAction<void>(ACTIONS.RECEIVED_STREAMHUB_NOT_READY);

export const muteAudio = createAction<boolean>(ACTIONS.MUTE_AUDIO);

export const muteVideo = createAction<boolean>(ACTIONS.MUTE_VIDEO);

export const muteVideoReturnAudio = createAction<boolean>(ACTIONS.MUTE_VIDEO_RETURN_AUDIO);

export const muteIntercomAudio = createAction<boolean>(ACTIONS.MUTE_INTERCOM_AUDIO);

export const prepareTimedOut = createAction<void>(ACTIONS.PREPARE_TIMED_OUT);

export const goBack = createAction<{ call: ICall; testStatus: TestStatus }>(ACTIONS.GO_BACK);

export const receivedPrepareResponse = createAction<void>(ACTIONS.RECEIVED_PREPARE_RESPONSE);

export const receivedJoinResponse = createAction<void>(ACTIONS.RECEIVED_JOIN_RESPONSE);

export const receivedLeaveResponse = createAction<void>(ACTIONS.RECEIVED_LEAVE_RESPONSE);

export const subscribeLiveServiceRequest = createAction<void>(ACTIONS.SUBSCRIBE_LIVE_SERVICE_REQUEST);

export const subscribeLiveServiceSuccess = createAction<void>(ACTIONS.SUBSCRIBE_LIVE_SERVICE_SUCCESS);

export const subscribeLiveServiceError = createAction<void>(ACTIONS.SUBSCRIBE_LIVE_SERVICE_ERROR);

export const videoReturnStopped = createAction<void>(ACTIONS.VIDEO_RETURN_STOPPED);

export const hungUp = createAction<void>(ACTIONS.HUNG_UP);
