import { logger } from 'utils/logger';
import { jsonParse } from 'utils/stringUtils';

export const getWebSocketProtocol = () => (window.location.protocol === 'http:' ? 'ws:' : 'wss:');

export interface IWsMessage {
  type: string;
  data?: unknown;
}

interface IWebsocketOpts<TMessage extends IWsMessage> {
  searchParams?: URLSearchParams;
  messageTypes?: TMessage['type'][];
}

export class WebsocketClient<TMessage extends IWsMessage> {
  public ws: WebSocket | null = null;
  // only for debugging and tracing
  private readonly wsPath: string;
  private reconnecting = false;
  private closedByUser = false;
  private retries = 0;

  public get isOpen() {
    return this.ws != null && this.ws.readyState === this.ws.OPEN;
  }

  constructor(
    private url: string,
    public opts?: IWebsocketOpts<TMessage>
  ) {
    this.wsPath = url.substring(url.lastIndexOf('/'));
  }

  public async close() {
    this.closedByUser = true;
    if (this.ws) {
      logger.debug('[WS] Closing Websocket Connection from this end ' + this.wsPath);
      this.ws.onmessage = (ev) => {
        // nothing to see here
      };
      try {
        this.ws.close(1000);
      } catch (e) {
        logger.warn('[WS] Could not close ' + this.wsPath);
        throw e;
      }
    }
  }

  public async connect(cb: (wsMessage: Blob | TMessage) => void): Promise<boolean> {
    return new Promise((res) => {
      this.ws = new WebSocket(this.url);

      this.ws.onopen = () => {
        logger.debug('[WS] CreateWebsocketConnection [onopen] ' + this.wsPath);
        res(true);
      };

      this.ws.onclose = (ev: CloseEvent) => {
        if (this.ws) {
          // Reconnects if close code !== 1000 (https://github.com/Luka967/websocket-close-codes)
          if (ev.code !== 1000 && !this.reconnecting && !this.closedByUser) {
            this.reconnecting = true;
            setTimeout(
              () => {
                logger.debug('[WS] CreateWebsocketConnection [onclose] reconnecting to ' + this.wsPath);
                this.reconnecting = false;
                this.retries++;
                this.connect(cb);
              },
              1_000 + this.retries * 250
            );
          }
        }
      };

      this.ws.onmessage = (message) => {
        // it's a thumbnail
        if (message.data instanceof Blob) {
          cb(message.data);

          // it's a regular message
        } else {
          const wsMessage = jsonParse<TMessage>(message.data);
          if (!wsMessage) {
            logger.error('[WS] Could not parse message: ', message.data);
            return;
          }
          // SPECIAL MESSAGE
          if (wsMessage.type === 'AUTH_ERROR') {
            logger.error('[WS] Authentication error ' + this.wsPath);
          } else {
            // Filters by message type if requested
            if (!this.opts?.messageTypes || this.opts?.messageTypes?.includes(wsMessage.type)) {
              cb(wsMessage);
            }
          }
        }
      };
    });
  }

  public send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.isOpen) {
      this.ws!.send(data);
    } else {
      logger.warn('WS not connected yet, cannot send the message:', data);
    }
  }
}
