import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const INITIAL_STATE: Record<string, number> = {};

// Selectors
export const createLoadingSelector = () =>
  createSelector(
    (state: RootState) => state.loading,
    (_: RootState, props: { requestNames: string[] }) => props.requestNames,
    (loadingFlags, actions) => actions.some((action) => loadingFlags[action] > 0)
  );

export const requestIsLoading = createSelector(
  [(state: RootState) => state.loading, (_, requestName: string) => requestName],
  (loadingFlags, requestName) => loadingFlags[requestName] > 0
);

export const loadingReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addMatcher(
    (action) => {
      return /(.*)\/(pending|rejected|fulfilled)/.test(action.type);
    },
    (state, action) => {
      const matches = /(.*)\/(pending|rejected|fulfilled)/.exec(action.type);

      if (!matches) {
        return;
      }

      const [, requestName, requestState] = matches;
      const currentCount: number = state[requestName] ? state[requestName] : 0;

      // Store whether a request is running at the moment or not
      // e.g. will be true when receiving GET_X_REQUEST
      // and false when receiving GET_X_SUCCESS / GET_X_ERROR
      state[requestName] = currentCount + (requestState === 'pending' ? 1 : -1);
    }
  );
});
